body, html{
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
}
nav.navbar.bootsnav .navbar-toggle {
	float: right;
	margin-right: 0px;
	margin-top: 10px;
}
.navbar.navFixed{

}
.navbar {
	/*	min-height: 125px;*/
}
navbar-brand {
	height: 70px;
	transition: all .5s ease;
}
nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle::after {
	line-height: 1em;
}

.navbar-right{
	margin-top: 30px;
}
.navFixed .navbar-right{
	margin-top: 8px;
}

nav.navbar.bootsnav ul.nav > li > a:hover, nav.navbar.bootsnav ul.nav > li > a:focus {
	/*may need to un inportant later*/
	color:#999999 !important;
}
nav.navbar.navFixed.bootsnav ul.nav > li > a{

}
nav.navbar.navFixed.bootsnav ul.nav > li > a:hover, nav.navbar.navFixed.bootsnav ul.nav > li > a:focus {
	color:#121212;
}

html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.no-background.bootsnav.on.no-full div.container div#navbar-menu.navbar-collapse.collapse.in ul.nav.navbar-nav.navbar-right{
	background:white;
}

html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full div.container div#navbar-menu.navbar-collapse.collapse.in ul.nav.navbar-nav{
	background: white;
}

.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full.a.navbar-brand img.logo{
	/*	width:40px;*/
}
/*.nav.navbar.bootsnav{
	background-color: none !important;
		background-color: transparent !important;

}
.navbar-default{
		background-color: none !important;
		background-color: transparent !important;
}
html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.no-background.bootsnav.on.no-full div.container div#navbar-menu.navbar-collapse.collapse.in{
	background: white;
	}*/
	nav.navbar.bootsnav .navbar-toggle{
		background: none !important;
		background: transparent !important;
	}
	nav.navbar.bootsnav .navbar-toggle i{
		color:#ffffff;
		font-size: 1.5em;
	}
	nav.navbar.bootsnav.no-background{
		transition: none;
	}
/*.carousel-inner {
    height: 90vh;
    }*/

    /*helpers*/
    #internal{
      padding-bottom: 50px;
    }
    #internal img{
      max-width: 100%;
    }
    .padding10{
    	padding:10px;
    }
    .padding20{
    	padding:20px !important;
    }
    .padding30{
    	padding:30px;
    }
    .padding40{
    	padding:40px;
    }
    .padding50{
    	padding:50px;
    }
    .padding-top-0{
      padding-top: 0 !important;
    }
    .padding-top-bottom-100{
    	padding:100px 0;
    }
    .marginTB20{
    	margin:40px 0 !important;
    }
    .marginTB40{
    	margin:40px 0 !important;
    }
    .marginTB60{
      margin:60px 0 !important;
    }
    .marginT80{
    	margin-top:80px;
    }
    .margin-top-20{
    	margin-top: 20px !important;
    }
    .margin-top-40{
    	margin-top: 40px !important;
    }
    .margin-top-50{
    	margin-top: 50px !important;
    }

    .margin-top-60{
      margin-top: 60px !important;
    }
    .margin-bottom-30{
    	margin-bottom: 30px !important;
    }


    .navTabInpage .active{
      background:#6C6C6C;
    }
    .navTabInpage .active li a{
      color:#ffffff;
    }
    .noMargin{
      margin:0 !important;
    }
    .noPadding{
      padding:0 !important;
    }

    .heading{
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 23px;
      text-transform: uppercase;
      color: #000;
      margin-bottom: 8px;
    }


    .noFloat{
    	float:none !important;
    }
    .bgWhite{
    	background:#ffffff;
    }
    .greyBg{
    	background:#E6E7E9;
    }
    /*font helpers*/
    .font18{
    	font-size: 18px;
    	font-size: 1.25rem;
    }
    .font-28{
    	font-size: 28px;
    	font-size: 1.750rem;
    }
    .internal-left-text{
     color: #707070;   
     font-family: 'Poppins', sans-serif;
     font-size: 16px;
     font-weight: 300;
     padding-top: 20px;
     text-align: right;
     text-transform: none;
   }
   internal-right-text{
    color: #707070;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    letter-spacing: .1px;
    padding-top: 20px;
    text-align: left;
    text-transform: none; 
  }

  .internal-left-right{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
    padding-top: 20px;
    text-transform: none;
    text-align: left;
    color: #707070; 
  }
  .internal-right-text, .internal-text-right{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;

    text-transform: none;
    text-align: left;
    color: #707070; 
  }
  .internal-text{
   font-family: 'Poppins', sans-serif;
   font-size: 16px;
   font-weight: 300;
   text-transform: none;
   text-align: left;
   color: #707070;   
 }

 .section-description h1{
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  text-align: center;
  padding-bottom: 3px;
  line-height: 1em;
}
.section-description h1 small{
  color:#333;
}

#internal h4.heading{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 23px;
  text-transform: uppercase;
  color: #000;
}





/*homepage*/
.carousel-color-overlay {
 background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
}
.slideH1 {
 font-size: 28px;
 font-size: 1.750rem;
 text-transform: uppercase;
 line-height: 1.5em;
}
.slideH1:before, .slideH1:after{
 display: block;
 height:5px;
 width:auto;
 background:rgba(255,255,255,.5);
 content: "";
 margin: 0 auto;
 max-width: 28.5em;
}
.slideH2{
 font-size: 16px;
 text-transform: uppercase;
 font-size: 1rem;
 font-weight: normal;
}
h2.constr-about-title{
 text-transform: uppercase;
}
.constr-under-line2 {
 background: #fcdb90 none repeat scroll 0 0;
 height: 2px;
 margin-bottom: 30px;
 margin-top: 20px;
 text-align: left;
 width: auto;
 z-index: 1;
}
.google-maps {
 position: relative;
 /*padding-bottom: 37%;*/
 height: 0;
 overflow: hidden;
}
.google-maps iframe {
 position: absolute;
 top: 0;
 left: 0;
 width: 100% !important;
 /*height: 22rem !important;*/
}
.homeContentBottom{
 background: url(../images/home-content-bottom.jpg);
 background-size:  cover;
 height: 22rem !important;
}
.homeContentBottom p{
 color:#404040;
}
.homeContentBottom ul li{
 list-style-type: disc;
}
.nopaddingR{
 padding-right:0 !important;
}
.nopaddingL{
 padding-left:0 !important;
}
.nopadding{
 padding-left:0 !important;
 padding-right:0 !important;
}
.noPaddingTop{
  padding-top: 0 !important;
}
.padding-30{
 padding:30px;
}
.footer-content p, .footer-content address{
 color:#ffffff;
 text-transform: uppercase;
 font-size: 14px;
}
footer p small{
 font-size: 12px;
 margin:0;
}
.whiteText{
  color:#ffffff !important;
}
/*services page*/
.service-heading{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 23px;
  padding-right: 70px;
  text-transform: uppercase;
  text-align: right;
  color: #000;

}
.promo-box-2{
  padding:40px 0;
}

.promo-box-14{
  padding:40px 0;
}
/*cta*/

[class*="bg__"] {
  /*  height: 150vh;*/
  /* fix background */
  background-attachment: fixed;
  /* center it */
  background-position: center center;
  /* Scale it nicely to the element */
  background-size: cover;
  /* just make it look a bit better ;) */
  &:nth-child(2n) {
    box-shadow: inset 0 0 1em #111;
  }
}
.bg__cta1{
  background-image: url(../images/cta-1-bg.jpg);
}
/*cta2*/
.bg__cta2{
  background-image: url(../images/cta-2-bg.jpg);
}
.bg__cta2  .promo-info-t{
  font-size: 34px;
  color:#ffffff;
  text-shadow: 2px 2px #000000;
  background: rgba(0, 0, 0, 0.5);
  display: inline-block;
  padding: 20px;
}
.bg__cta2 .promo-btn{
}
/*about us*/
.bg__cta3{
  background-image: url(../images/cta-2-bg.jpg);
}
.bg__cta3 .promo-btn{
  margin-top:-66px;
}
.bg__cta3 .promo-info-t{
  font-size: 2rem;
  text-shadow: 2px 2px #000000;
  background: rgba(0, 0, 0, 0.5);
  display: inline-block;
  padding: 20px;
}


/*cta14*/
.bg__cta14{
  background-image: url(
    ../images/cta-14-bg.jpg
    );
}

/*hoseContactCta*/
.hoseContactCta, .dixonContactCta, .specialsCta{
  text-align: left;
}
.hoseContactCta p, .hoseContactCta h5, .dixonContactCta p, .specialsCta p{
  color:#ffffff;
}
.hoseContactCta h5{
  font-size: 24px;
  font-size: 1.412rem;
  text-transform: uppercase;
  margin-top:40px;
}
.hoseContactCta .error, .dixonContactCta .error, .specialsCta .error{
  color:red;
}
.hoseContactCta .success, .dixonContactCta .success, .specialsCta .success{
  color:#848491;
  margin-bottom: 90px;
}

.hoseContactCta p strong{
  margin-top:20px;
}
.dixonContactCta p strong, .specialsCta p strong{
  margin-top:20px;
  display:  block;  
}

.specialsPDFButton{
  backgournd:#464646;
  padding:10px 20px;
  margin:10px 0;
}


/*service pages*/
.border-solid-grey{
  border-left:  2px solid #dadada;
  border-right: 2px solid #dadada ;
  border-bottom: 2px solid #dadada ;
  /* border:4px solid #6C6C6C;*/
}
.bg-grey{
  background:#6c6c6c;
}
.bg-red{
  background: #e21d39;
  color:#ffffff;
}
.tabPaddingSpecial{
  padding:10px 10px !important;
  letter-spacing: .1px;
  line-height: 1.2em !important;
  cursor:   pointer;  
  text-decoration:  none;
  font-size: 13px !important;
}
.nav-tabs > li > a{
  margin-right: 0 !important;
  border-radius: 0px 0px 0 0;
}
.nav-tabs > li > a:hover{
  border:none;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
  /*  background:#6c6c6c;*/
  background: #e21d39;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
  border:none !important;
}
.nav-tabs > li:hover a{
  text-decoration: none;
}
.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  /*  background-color: #e21d39;*/
  background:#6c6c6c;
  opacity: .8;
}

#internal h3{
  text-transform: uppercase;
  font-size: 24px;
}

#contactPage .textarea-box {
  height: 130px !important;
}

#contactPage .hvr-bounce-to-right{
  color:black;
}
#contactPage .hvr-bounce-to-right:hover, #contactPage .hvr-bounce-to-right:focus {
  color:#ffffff;
}

#contactPage .success{
  min-height: 140px !important;
}

.disc li{
  list-style-type: disc
}
/*contact page*/
.contactPage{
  color:#ffffff;
}
.weWelcome{
  font-size: 1.5em;
  color:#ffffff;
  margin-bottom: 30px;
}
#internal p{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 300;

  text-transform: none;
  color: #707070; 
}

.list-style-disc li{
  list-style-type: disc;
  margin-left:10px;
  font-size: .9em;
  list-style-position: inside;
}
.list-style-custom{
  margin:0 !important;
  font-size: .9em;

}
.list-style-custom li{
  text-indent: -0.88em;
  margin-left: 0.88em;
  position: relative;
  padding-right: 1em;
}

.list-style-custom li:before{
  content: '•';
  font-size: 1.2em;
  margin-right: .25em;

}
.list-style-custom li:after{
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -.5em;
}




.dixon-catalog{
  text-align: center;
  padding:10px 30px;
  margin-top: 20px;
  color:#ffffff;
  font-size: 24px;
  border-radius: 5px;
}
.dixon-catalog a{
  color:#ffffff;
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
 body, html{
  font-size: 18px;
  font-size: 1.125rem;
}   
.navbar{
  min-height: 145px;
} 
.logo{
  width: auto;
  padding: 10px 20px;
}
.navbar-default{
  background: transparent;
}
html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.no-background.bootsnav.on.no-full div.container div#navbar-menu.navbar-collapse ul.nav.navbar-nav.navbar-right{
  background:white;
}
.navFixed{
  position: absolute !important;	
}
nav.navbar.bootsnav .navbar-brand{
  float: none;
}
.navbar-brand{
  height: auto;
}
h2.constr-about-title{
  font-size: 28px;
  font-size: 1.750rem;
  text-align: center;
}
#brandsWeCarry img{
  max-width: 100%;
  text-align: center;
  margin:0 auto;
}
[class*="bg__"] {
  height: auto;
}
.arch-desc-left-1, .arch-desc-right,.arch-desc-left-2{
  text-align: center;
}

}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
 .navbar-brand {
  float: none !importante;
}
.arch-srv-heading{
  margin-top: 20px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
 .navbar-brand {
  float: left !important;
}
nav.navbar.bootsnav .navbar-brand    {
  float: left;
}
/*[class*="bg__"] {
  height: 150px;
  }*/

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
 body, html{
  font-size: 16px;
  font-size: 1rem;
}
.navbar {
  min-height: 125px;
}
.navbar-default{
  background: #ffffff;
}
.logo{
  width: auto;
  margin-bottom: auto;
  background: transparent;
  padding: 0px;
  border: none;
  border-radius: 0px;
}
.navbar-brand{
  height: 20px;
}
nav.navbar.bootsnav ul.nav > li > a{
  font-size: 14px;
  padding: 20px 10px;
}

html.js.cssanimations body div.wrapper nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full.no-background div.container div.navbar-header a.navbar-brand{
  overflow: visible;
}
html.js.cssanimations body div.wrapper nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full div.container .navbar-brand{
  overflow: hidden;
  min-height: 70px;
}
html.js.cssanimations body div.wrapper nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full.no-background{
  height:125px;
}
html.js.cssanimations body div.wrapper nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full{
  min-height: 70px;
}

/*home nav*/
html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full.no-background div.container .navbar-brand {
  overflow: visible;
}
html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full div.container .navbar-brand  {
  overflow: hidden;
  min-height: 70px;
}
html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full.no-background{
  height:125px;
}
html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.bootsnav.on.no-full{
  min-height: 70px;
}
html.js.cssanimations body div.wrapper header#home.header nav.navbar.navbar-default.navbar-fixed.white.no-background.bootsnav.on.no-full div.container div#navbar-menu.navbar-collapse ul.nav.navbar-nav.navbar-right{
  background:transparent;
}
.navFixed{
  position:fixed!important;	
}
h2.constr-about-title{
  font-size: 20px;
  font-size: 1.250rem;
  text-align: left;
}
#brandsWeCarry .border-right{
  border-right:1px solid #dadada;
  padding-right: 10px;
}
#brandsWeCarry .border-bottom{
  border-bottom:1px solid #dadada;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#brandsWeCarry .heightFix{
  height: 8em;
}
.bg__cta2  .promo-info-t{
  font-size: 34px;
}
.arch-desc-left-1{
  text-align: right;
}
.arch-desc-right{
  text-align: left;
}
.arch-desc-left-2{
  text-align: right;
}

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
 nav.navbar.bootsnav ul.nav > li > a{
  font-size: 16px;
  padding: 20px 10px;
}
.arch-srv-heading{
  margin-top: 80px;
}

}

/* Icon font for arrow icons */
@font-face {
  font-family: 'icomoon';
  src:url('../fonts/icomoon_arrows/icomoon.eot');
  src:url('../fonts/icomoon_arrows/icomoon.eot?#iefix') format('embedded-opentype'),
  url('../fonts/icomoon_arrows/icomoon.woff') format('woff'),
  url('../fonts/icomoon_arrows/icomoon.ttf') format('truetype'),
  url('../fonts/icomoon_arrows/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  } /* Iconfont by Icomoon http://icomoon.io/ */


  /* Accordion style */
  .cbp-ntaccordion {
    list-style: none;
    margin: 0 0 50px 0;
    padding: 0;
  }

  .cbp-ntsubaccordion {
    list-style: none;
  }

  .cbp-ntaccordion .cbp-nttrigger {
    cursor: pointer;
  } 

  .cbp-ntaccordion h3 {
    margin: 0 0 0.3em;
    padding: 1em 0 0.5em;
    border-bottom: 1px solid #ddd;
    font-size: 1.2em;
    line-height: 1em;
    font-weight: 300;
    background:#ccc;
  }

  .cbp-ntaccordion h4 {
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.4em;
    padding: 0.5em 0 0.5em;
    margin: 0 0 0.5em;
  }

  .cbp-ntaccordion h5 {
    font-size: 1.2em;
    color: #aaa;
    padding: 0.5em 0 0.5em;
    margin: 0 0 0.5em;
  }

  .cbp-ntaccordion .cbp-ntcontent p {
    color: #888;
    font-size: 1.25em;
    font-weight: 300;
    line-height: 1.5;
    padding: 0.2em 0 1.5em;
    margin: 0;
  }

  /* Arrow icons */
  .cbp-ntaccordion > li > .cbp-nttrigger:before,
  .cbp-ntsubaccordion > li > .cbp-nttrigger:before {
    font-family: 'icomoon';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #ddd;
    margin-right: 0.5em;
    -webkit-font-smoothing: antialiased;
  }

  .cbp-ntaccordion > li > .cbp-nttrigger:before {
    font-size: 75%;
  }

  .cbp-ntaccordion > li > .cbp-nttrigger:before {
    content: "\36";
  }
  .cbp-ntaccordion > li > .cbp-nttrigger:hover:before {
    content: "\35";
    color: inherit;
  }
  .cbp-ntaccordion > li.cbp-ntopen > .cbp-nttrigger:before,
  .no-js .cbp-ntaccordion > li > .cbp-nttrigger:before {
    content: "\34";
    color: inherit;
  }

  .cbp-ntsubaccordion > li > .cbp-nttrigger:before {
    content: "\32";
  }
  .cbp-ntsubaccordion > li > .cbp-nttrigger:hover:before {
    content: "\33";
    color: inherit;
  }
  .cbp-ntsubaccordion > li.cbp-ntopen > .cbp-nttrigger:before,
  .no-js .cbp-ntsubaccordion > li > .cbp-nttrigger:before {
    content: "\31";
    color: inherit;
  }

  /* Initial height is zero */
  .cbp-ntaccordion .cbp-ntcontent {
    height: 0;
    overflow: hidden;
  }

  /* When open, set height to auto */
  .cbp-ntaccordion .cbp-ntopen > .cbp-ntcontent,
  .cbp-ntsubaccordion .cbp-ntopen > .cbp-ntcontent,
  .no-js .cbp-ntaccordion .cbp-ntcontent {
    height: auto;
  }

  /* Example for media query */
  @media screen and (max-width: 32em) { 

    .cbp-ntaccordion {
      font-size: 70%;
    }

  }

  /*Hello Eddie!*/