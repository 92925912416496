/* brian styles */
.contact .contact-box .contact-form .textarea-box {
	height: 50px;
} 
.contact .contact-box .contact-form button {
	margin-top: 20px;
	width: 100%;
	height: 49px;
	margin-bottom: 100px;
} 
table#dixon-products {
	text-align: left;
}
table#dixon-products tr td {
	padding: 10px;
	font-size: 14px;
	line-height: 1.5em;
}
table#dixon-products {
    border-collapse: collapse;
}
table#dixon-products, th, td {
    border: 2px solid #dadada;
}
.text-bold {
	font-weight: 700 !important;
	color: #333 !important;
}