 /*=======================================================================

* Template Name: CONCORD Construction Template
* Author: The Creo
* Version: 1.0
* Design and Developed by: The Creo Team
======================================================================= */


/*
====================================
[ CSS TABLE CONTENT ]
------------------------------------
    1.0 - General Style
            - Link Style
            - Heading (Typography)
            - Background Color
            - Pre Loader
    2.0 - Home Page Style
    3.0 - Service Section
    4.0 - Portfolio Section
    5.0 - Portfolio-Project-Details
    6.0 - About Us
    7.0 - Skill Bar
    8.0 - Testimonial Section
    9.0 - Client Section
    10.0 - Team Section
    11.0 - Package Section
    12.0 - Fun Factors
    13.0 - Blog Section
    14.0 - Blog List Page
    15.0 - Single Blog Page
    16.0 - Contact Section
    17.0 - Google Map
    18.0 - Responsive

-------------------------------------
[ END CSS TABLE CONTENT ]
=====================================
*/

/* --------------------------------------
=========================================
GENERAL STYLES
=========================================
-----------------------------------------*/
/* Google Fonts */

@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700';
@import 'https://fonts.googleapis.com/css?family=Poppins:400,500,700';

@font-face {
  font-family: Montserrat-Ultralight;
  src: url(../assets/fonts/Montserrat-UltraLight.woff);
}

html {
    font-size: 100%;
    width: auto !important;
    overflow-x: hidden !important;
}

body {
    height: 100%;
    background: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    width: 100%;
    overflow-x: hidden !important;
    margin: auto !important;
}

hr{
  border-color: transparent;
}

strong{
  font-weight: 700;
}


.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}

.white-color{
  color: #fff;
}

/* Internet Explorer 10 in Windows 8 and Windows Phone 8 Bug fix */

@-webkit-viewport {
    width: device-width;
}

@-moz-viewport {
    width: device-width;
}

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

/* Link style
/* ------------------------------ */

a {
   color: #464646;
    -webkit-transition: all ease 0.25s;
            transition: all ease 0.25s;
}

a:hover {
    text-decoration: none;
    opacity: 0.9;
}

a:active {
    text-decoration: none;
    opacity: 0.7;
}

a:visited {
    text-decoration: none;
    opacity: 0.7;
}

.center-div{
  text-align: center;
}

a:focus{
  text-decoration: none;
  opacity: 0.7;
}

.btn{
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 100%;
    margin: 10px 0;
    outline: 0 none;
    padding: 10px 20px;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    border-radius: 0px;
    /*background: transparent;*/
}

.btn-price{
  margin-top: 10px;
  border: 1px solid;
  border-color: #949494;
  border-left: none;
  border-right: none;
  border-bottom: none;
  width: 100%;
  margin-bottom: 0px;
}

.btn-home{
  color: #fff;
  border: 1px solid #fff;
}

.agency-btn-1{
  background-color: #5ba1ab;
  color: #ffffff;
  float: left;
}

.btn-home:hover{
  color: #000;
}

.btn-home:hover a{
  color: #000;
}

.btn-home a{
  color: #fff;
}

.btn-home a:hover{
  color: #000;
}

.btn-home:hover a:visited{
  color: #000;
}

.btn-home a:visited{
  color: #fff;
}

.btn:focus,
.btn:active {
    outline: inherit;
}

textarea:focus, input:focus {
    color: #ffffff;
}

/* Scroll Down */
 .scroll-btn {
  display: block;
  position: absolute;
  bottom: 1%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 100;
}
.scroll-btn > * {
  display: inline-block;
  line-height: 10px;
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  color: #000;
  letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
  color: #000;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.scroll-btn .mouse {
  position: relative;
  display: block;
  width: 25px;
  height: 40px;
  margin: 0 auto 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.scroll-btn .mouse > * {
  position: absolute;
  display: block;
  /*top: 30%;*/
  left: -10%;
  font-size: 32px;
}

/* Other fixes*/

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
            box-sizing: border-box;
}

ul,
ol {
    padding-left: 0 !important;
}

li {
    list-style: none;
}

.nopadding{
  padding: 0px;
}

.numbers{
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
}

/*---------------------------------------
   HEADING
-----------------------------------------*/

h1 {
    font-size: 3.75rem;
    line-height: 4.688rem;
}

h2 {
    
    font-weight: 700;
    font-size: 42px;
    letter-spacing: 1px;
    line-height: 4.063rem;
    text-transform: capitalize;
}

h3 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 300;
}

h4 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
}

h5 {
    font-size: 18px;
    font-weight: 400;
}

h6 {
    font-size: 12px;
   
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
}

p { color: #707070;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.5em;
    letter-spacing: .1px;
}

/*---------------------------------------
   TEXTS AND BG
-----------------------------------------*/

.section-title {
    color: #000;

}

.text-dark{
  color: #000;
}
.text-white{
  color: #FFF;
}
.text-dark-blue{
  color: #004dff;
}

.bg-white{
  background: #fff;
}

.section-top{
  padding-top: 80px;
}
.section-bottom{
  padding-bottom: 80px;
}

.section-header {
    padding-bottom: 50px;
}

.section-header h2 {
    margin-bottom: 5px;
}


.section-header {
  padding-left: 10px;
  text-align: left;
  /*margin: 0 0 5px;*/
}
.section-title span{

  text-align: left;

}

.section-header .smt {
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  display: table;
  position: relative;
  font-size:45px;
  padding: 0 22px;
  text-transform: uppercase;
}

.section-header-int .smt {
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  display: table;
  position: relative;
  font-size:45px;
  padding: 0 22px;
  text-transform: uppercase;
}

.section-header .smt:after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #c58b68;
  position: absolute;
  left: 0;
  top: 50%;
  width: 42px;
  margin-top: -1px;
}

.section-header .smt > span {
  display: block;
  padding: 0 30px;
}

.section-sub-header {
  padding-left: 10px;
  text-align: left;
  /*margin: 0 0 5px;*/
}

.section-sub-header .smt {
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
  display: table;
  position: relative;
  font-size:12px;
  /*padding: 0 6px;*/
  text-transform: uppercase;
}

 .section-header .smt:after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #c58b68;
  position: absolute;
  left: 0;
  top: 50%;
  width: 62px;
  margin-top: -1px;
}

.section-sub-header .smt > span {
  display: block;
  padding: 0 30px;
}

 .section-header .smt:after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #1D1C1C;
  position: absolute;
  left: 0;
  top: 50%;
  width: 42px;
  margin-top: -1px;
}

.section-header .smt:after {
  left: auto;
  right: 0;
}

.section-white .section-header h2{
  color:#FFF;
}

/*section header-2*/

.section-header-2 {
 /*   padding-bottom: 50px;*/
}

.section-header-2 h4 {
    margin-bottom: 5px;
}


.section-header-2 {
  padding-left: 10px;
  text-align: center;
  /*margin: 0 0 5px;*/
}
.section-title-2 {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color:#f5f5f5;
}
.section-title-3 {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 12px;
  color: #718c9e;
}

.section-title-4{
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 12px;
  color: #718c9e;
  padding-bottom: 30px;
}


.section-header-2 .section-description{
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    text-align: center;
    padding-bottom: 3px;
}

.section-header-2 .section-description-2{
    font-family: "Raleway", sans-serif;
    font-weight: 200;
    font-size: 38px;
    text-transform: capitalize;
    letter-spacing: 2px;
    display: inline-block;
    position: relative;
    text-align: center;
    padding-bottom: 3px;
}

/*Line*/

.colored-line {

    margin: auto;
    z-index: 1;
    width: .5px;
    height: 27px;
    background: #000;
}

.colored-line-2 {


    z-index: 1;
    width: .5px;
    height: 27px;
    background: #000;
}


.white-line {
    margin: auto;
    z-index: 1;
    width: 165px;
    height: 1px;
    background: #ffffff;
}

.black-line {
    text-align: left;
    margin: auto;
    z-index: 1;
    width: 65px;
    height: 2px;
    background: #000;
}

.under-line{
    /*margin: auto;*/
    z-index: 1;
    width: 35px;
    height: 1px;
    background: #000;
    text-align: left;
    margin-bottom: 10px;
}

.under-line-1{
    margin: auto;
    z-index: 1;
    width: 55px;
    height: 1px;
    background: #000;
    text-align: left;
    margin-top: 10px;
}

.yellow-line-1{
    margin: auto;
    z-index: 1;
    width: 75px;
    height: 2px;
    background: #f9b701;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
}

.under-line-2{
    margin: auto;
    z-index: 1;
    width: 220px;
    height: 1px;
    background: #dadada;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}

/*Margin*/

.bottom-border{
    border-bottom: 1px solid;
    padding-bottom: 10px;
    display: inline-block;

}

.m-bot-40{
  margin-bottom: 40px;
}
.m-bot-60{
  margin-bottom: 60px;
}
.m-top-60{
  margin-top: 60px;
}

.fit-img{
  width: 100%;
  height: auto;
}

.text-center{
  text-align: center;
}

/*Divider*/
.divider {
    width: 100%;
    margin: 30px 0;
    position: relative;
    color: #e2e2e2;
    display: inline-block;
}

.divider-2 {
    width: 100%;
    margin: 30px 0;
    position: relative;
    color: #e2e2e2;
    display: inline-block;
}

.divider.d-single {
    width: 100%;
    height: 1px;
    border-color: #e2e2e2;
    border-top-width: 1px;
}

.divider-2.d-single {
    width: 100%;
    height: 1px;
    border-color: #e2e2e2;
    border-top-width: 1px;
}

.divider.d-double {
    width: 100%;
    height: 8px;
    border-color: #e2e2e2;
    border-top-width: 1px;
    border-bottom-width: 1px;
}




.d-single.d-solid {
    border-top-style: solid;
}



.timer{
  font-family: 'Advent Pro', sans-serif;
  font-size: 48px;
  font-weight: 700;
}


.single-page-overlay{
  background-color: rgba(44,44,44,.6);
}

.single-page-image-parallax{
   width: 100%;
  height: 450px;
  /*position: relative;*/
  padding-bottom: 20px;

}


header {
    -webkit-background-size: cover;
            background-size: cover;
}

header .color-overlay {
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
}

.logo{
  width: 40px;
  height: auto;
}

/* PRE LOADER
/* ------------------------------ */

.no-js .preloader{
  display: none;
}

.preloader {
    position: fixed;
    background-color: #fff;
    z-index: 99999;
    height: 100%;
    width: 100%;
    overflow: hidden !important;
}

.status {
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
            background-size: cover;
}

.ip-header {
  position: fixed;
  top: 0;
  z-index: 100;
  min-height: 480px;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
}

.ip-header h1 {
  margin: 0;
}

.ip-loader {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: default;
  pointer-events: none;
}

.ip-loader {
  bottom: 50%;
}

.ip-header .ip-inner {
  display: block;
  margin: 0 auto;
}

.ip-header .ip-loader svg path {
  fill: none;
  stroke-width: 3;
}

.ip-header .ip-loader svg path.ip-loader-circlebg {
  stroke: #ddd;
}

.ip-header .ip-loader svg path.ip-loader-circle {
  -webkit-transition: stroke-dashoffset 0.2s;
  transition: stroke-dashoffset 0.2s;
}

/* Animations */

/* Initial animation of header elements */
.loading .ip-loader {
  opacity: 1;
  -webkit-animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
  animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
}

.loading .ip-loader {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes animInitialHeader {
  from { opacity: 0; -webkit-transform: translate3d(0,800px,0); }
}

@keyframes animInitialHeader {
  from { opacity: 0; -webkit-transform: translate3d(0,800px,0); transform: translate3d(0,800px,0); }
}

/* Header elements when loading finishes */
.loaded .ip-loader {
  opacity: 1;
}

@-webkit-keyframes animLoadedLogo {
  to { -webkit-transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); }
}

@keyframes animLoadedLogo {
  to { -webkit-transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); }
}

.loaded .ip-loader {
  -webkit-animation: animLoadedLoader 0.5s cubic-bezier(0.7,0,0.3,1) forwards;
  animation: animLoadedLoader 0.5s cubic-bezier(0.7,0,0.3,1) forwards;
}

@-webkit-keyframes animLoadedLoader {
  to { opacity: 0; -webkit-transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); }
}

@keyframes animLoadedLoader {
  to { opacity: 0; -webkit-transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); }
}

/* Header animation when loading finishes */
.loaded .ip-header {
  -webkit-animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
  animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
}

@-webkit-keyframes animLoadedHeader {
  to { -webkit-transform: translate3d(0,-100%,0); }
}

@keyframes animLoadedHeader {
  to { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
}

/* No JS */
.no-js .ip-header {
  position: relative;
  min-height: 0px;
}

.no-js .ip-header .ip-logo {
  margin-top: 20px;
  height: 180px;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.no-js .ip-header .ip-logo svg path {
  fill: #fff;
}

/*Bootnav navigation */

nav.navbar.bootsnav ul.nav > li > a{
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 300;
  padding: 20px 15px;
  text-transform: uppercase;
}

nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a{
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: 300;
  padding: 5px 10px;
  text-transform: uppercase;
}

/*
Color
=========================== */
nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a:hover,
.side .widget ul.link li a:hover,
.side .widget ul.link li a:focus,
.check-list li:before,
ul.cart-list > li > h6 > a,
.attr-nav > ul > li > a:hover,
.attr-nav > ul > li > a:focus,
nav.navbar-sidebar ul.nav li.dropdown.on > a,
nav.navbar-sidebar .dropdown .megamenu-content .col-menu.on .title,
nav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li a:hover,
nav.navbar ul.nav li.dropdown.on > a,
nav.navbar.navbar-inverse ul.nav li.dropdown.on > a,
nav.navbar-sidebar ul.nav li.dropdown.on ul.dropdown-menu li.dropdown.on > a,
nav.navbar .dropdown .megamenu-content .col-menu.on .title,
nav.navbar ul.nav > li > a:hover,
nav.navbar ul.nav li.active > a,
nav.navbar li.dropdown ul.dropdown-menu > li a:hover{
    color: #121212;
    background: rgba(0, 0, 0, 0.07);
}

nav.navbar.navbar-transparent ul.nav > li > a:hover,
nav.navbar.no-background ul.nav > li > a:hover,
nav.navbar ul.nav li.scroll.active > a,
nav.navbar.navbar-dark ul.nav li.dropdown ul.dropdown-menu  > li > a:hover,
nav.navbar ul.nav li.dropdown.on > a,
nav.navbar-dark ul.nav li.dropdown.on > a{
/*    color: #121212 !important;
    background: rgba(0, 0, 0, 0.07);*/
}

@media(max-width:920px){
    nav.navbar .dropdown .megamenu-content .col-menu ul > li > a:hover,
    nav.navbar.navbar-dark .dropdown .megamenu-content .col-menu .title:hover{
        color: #121212 !important;
        background: rgba(0, 0, 0, 0.07);
    }
}

/*
Border
=========================== */
ul.cart-list > li.total > .btn{
    border-color: #121212;
}

/*
Background
=========================== */
ul.cart-list > li.total > .btn,
.attr-nav > ul > li > a span.badge,
nav.navbar .share ul > li > a:hover,
nav.navbar .share ul > li > a:focus{
    background-color: #121212;
}

ul.cart-list > li.total > .btn:hover,
ul.cart-list > li.total > .btn:focus{
    background-color: #121212 !important;
}


nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle:after{
  font-family: 'FontAwesome';
  content: "\f107";
  margin-left: 4px;
  margin-top: 2px;
}

.top-search .input-group{
  margin: 0px auto;
}
.attr-nav > ul > li > a{
  padding: 16px 15px;
}

.sticky-navigation {
  min-height: 60px;
  background: #FFFFFF;
  opacity: 1;
  padding: 0 !important;
  margin-bottom: 0 !important;
  -webkit-backface-visibility: hidden;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.08);
          box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.08);
}

.sticky-navigation .navbar-header img {
  max-height: 30px;
  -webkit-backface-visibility: hidden;
}

.sticky-navigation .main-navigation .current a {
  position: relative;
  outline: none;
}

.sticky-navigation .main-navigation li a {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
}

.sticky-navigation .main-navigation li a:hover{
  background: none;
}

.sticky-navigation .main-navigation li a:active{
  background: none;
}

.sticky-navigation .main-navigation li a:focus{
  background: none;
}

.navbar-brand{
  padding: 10px;
}

.navbar-brand .logo-title{
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
    font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 3px;
}

.navbar-header a:hover{
  color: rgba(0,0,0,0.5);
}

.navbar-header a:focus{
  color: rgba(0,0,0,0.5);
}
.navbar-header a:active{
  color: rgba(0,0,0,0.5);
}

header .only-logo .navbar {
    background: none;
    padding: 60px;
}

header .only-logo .navbar .navbar-header {
    float: none;
}

.intro-section {
  margin: 30vh auto;
}

.intro-section-pad {
  padding-top: 30vh;
}

/*Construction service creative*/

.arch-abt-p-st{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-align: right;
  padding-right: 25px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
  .arch-abt-p-t{
  padding-top: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 23px;
  text-transform: none;
  text-align: right;
  padding-right: 25px;
  text-transform: uppercase;
}
.arch-right-pic-1{
 /* margin-top: 80px;*/
  width: 80%;
  height: 385px;
  background: url(../images/construct/srp-1.jpg) no-repeat;
  background-size: cover;
}
.arch-right-pic-2{
  margin-top: 80px;
  width: 80%;
  height: 385px;
  background: url(../images/construct/srp-2.jpg) no-repeat;
  background-size: cover;
}
.left-pic-1{
  margin-top: 80px;
  width: 100%;
  height: 385px;
  background: url(../images/construct/slp-1.jpg) no-repeat;
  background-size: cover;
}
.center-pic img{
  width: 100%;
  padding: 40px 0px;
}

.arch-srv-block-1{
  padding-bottom: 50px;
}
.arch-srv-heading{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 23px;
  margin-top: 80px;
  padding-right: 70px;
  text-transform: uppercase;
  text-align: right;
  color: #000;
}

.arch-srv-heading.right{
  text-align: left;
  padding-left: 70px;
}

.arch-srv-subhead{
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: right;
  padding-right: 70px;
}
.arch-srv-subhead.right{
    text-align: left;
    padding-left: 70px;
}

.arch-desc-left-1{
    font-family: 'Poppins', sans-serif;
   font-size: 16px;
   font-weight: 300;
   padding-top: 20px;
   padding-left: 70px;
   padding-right: 70px;
   text-transform: none;
   text-align: right;
   color: #18191b;
}
.arch-desc-left-2{
    font-family: 'Poppins', sans-serif;
   font-size: 16px;
   font-weight: 300;
   padding-top: 20px;
   padding-left: 70px;
   padding-right: 70px;
   text-transform: none;
   text-align: right;
   color: #18191b;
}


.arch-desc-right{
    font-family: 'Poppins', sans-serif;
   font-size: 16px;
   font-weight: 300;
   padding-top: 20px;
   padding-left: 70px;
   padding-right: 70px;
   text-transform: none;
   text-align: left;
   color: #18191b;
}


.intr-bg-color{
background: #f5f5f5;
background-size: 400% 400%;
}


/*Skills*/
.int-skill{
  padding-top: 50px;
  padding-bottom: 20px;
}


.intr-page-t{
  padding-top: 210px;
    font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 45px;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 3px;
}



.interior-counter{
  letter-spacing: 5px;
}

.interior-counter.sub-title-overlay{
  color: #e2beab;
  padding-left: 90px;
}

.intr-abt-desc{
  padding-bottom: 40px;
}


/*Feature-work-2*/

.work-grids{
  position:relative;
  overflow:hidden;
}

.img-text {
  color: #f5f5f5;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  font-size: 18px;
    position: absolute;
    top: 0;
  left: 0;
    padding: 2.5em 2em 0;
    text-align: center;
    width: 100%;
  -webkit-transition: .5s all;
    transition: .5s all;
  -moz-transition: .5s all;
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
  -moz-transition-delay: .2s;
}

.img-text p{
  color: #f5f5f5;
  font-weight: 400;
    font-family: 'Poppins', sans-serif;
  font-size: 16px;

}

.img-caption::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    /*left: 14px;*/
    width: 100%;
    border: 175px solid rgba(32, 33, 33, 0.8);
    border-bottom: 65px solid transparent;
    height: 55%;
    -webkit-transition: .5s all;
    transition: .5s all;
  -moz-transition: .5s all;
}
.work-grids:hover div.img-text {
    top: -32%;
}
.work-grids:hover div.img-caption::before {
    top: -46%;
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
  -moz-transition-delay: .3s;
}

.agency-abt{
    background-color:
}

/* =-=-=-=-=-=-= Concord-FINANCE-Agency- =-=-=-=-=-=-= */


.about-us-fin{
  text-align: left;
  height: 512px;
}

.about-us-fin .col-md-6{
  padding-right: 0px;
  background: #f7f7f7;
  height: inherit;
}
.about-us-fin .fin-img .fit-img{
  height: 512px;
}
.about-us-fin .accordion {
  padding-right: 60px;
}

.studio-sub-heading{
  color: #fefefe;
}

.service-faq-t{
  font-weight: 400;
    font-family: 'Poppins', sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
}

.fin-faq{
  height: 483px; /* equal to image beside it + 12px */
  background-color: #f4f4f4;
  padding-top: 30px;
  padding-right: 100px;
  padding-left: 50px;
}

.fin-faq-t{
  color: #282828;
  text-align: left;

}

.fin-img-overlay{
  position: absolute;
    top: 0%;
    left: 10%;
    padding: 1em;
    width: 81%;
    height: 100%;
}

.fin-img-overlay-t h4  {
  padding-top: 30px;
  padding-bottom: 5px;
      font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    text-transform: none;
}
.fin-img-overlay-t h3  {
  padding-bottom: 20px;
      font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 31px;
    color: #fff;
    text-transform: capitalize;
}


.fin-story-img {
/*  background: url('../images/finance/feature.jpg');*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: initial;
  background-repeat: no-repeat;

}


.fin-story-overlay {
  position: relative;
  background-color: rgba(12, 19, 31, 0.5);
}

/*Finanace Service Page*/

.fin-srv-title{
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  text-align: left;
  text-transform: uppercase;
  color: #1f213f;
}


.fin-block {
    margin: 0 auto;
}

.fin-block .single-block{
    margin: 10px;
    padding: 15px;
    border: 1px solid rgba(28,28,28,0.9);
}

.fin-block .single-block h5 {
    font-family: 'Poppins', sans-serif;
   padding-top: 5px;
   padding-bottom: 10px;
   font-size: 15px;
   font-weight: 400;
   text-transform: uppercase;
   letter-spacing: 3px;
   line-height: 14px;
   color: #282828;
}
.fin-block .single-block p {
   text-align: center;
}


.fin-block .single-block .icon-container {
    display: block;
    min-height: 60px;
    margin-top: 5px;
    padding-top: 5px;
}

.fin-block .single-block .icon-container .icon-yellow {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    line-height: 55px;
    font-size: 31px;
    color: red;
    text-align: center;
    color: rgba(249, 183, 1, 1)

}


/*Home Page*/

.funfactor.consult-fun .color-overlay{
  padding-top: 70px;
}


.text-white-7{

  font-size: 90px;
  letter-spacing: 3px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
}

.text-white-7 span{

  font-size: 45px;
  letter-spacing: 1px;
  color: #f5f5f5;
  font-weight: 500;
  text-transform: none;
  padding-bottom: 20px;
  text-align: left;
}

.text-white-8{

  font-size: 70px;
  letter-spacing: 3px;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
}

.text-white-8 span{

  font-size: 45px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 200;
  text-transform: capitalize;
  padding-bottom: 20px;
  text-align: left;
}


.srv-block-1{
  background: #202121;
}
.srv-block-2{
  background:#3c3c3c;
}
.srv-block-3{
  background: #262626;
}
.srv-block-4{
  background: #404040;
}
 .banner-text_grid {
  padding: 2em 2em;
}

.srv-block-t{
  padding-top: 10px;
  font-size: 20px;

  font-weight: 400;
  color: #c5c5c5;
  text-transform: uppercase;
  letter-spacing: 1px;

}

.banner-sec{
  position:relative;

}
.banner-top{
  position: absolute;
  bottom: -10em;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 0px 30px;
}

 .banner-text_grid .icon i {
  font-size: 40px;
  color: #f44336;
  padding: 0 0 0 10px 0;
}

.banner-text_grid h4 {

  font-size: 15px;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.banner-text_grid p {

 font-weight: 300;
  font-size: 16px;
  padding: 0.5em 0;
  color: #cccccc;
}

@media (max-width:900px){
  .banner-text {
  float: left;
  /*width: 33.3%;*/
}
}

@media (max-width:800px){
  .banner-top {
  bottom: -8em;
  width: 100%;
}
.banner-text {
  float: left;
  /*width: 33.3%;*/
}
.banner-text_grid img {
  border: 4px solid #eee;
  width: 52%;
}
}

@media (max-width:768px){
  .banner-text {
  float: left;
  /*width: 33.3%;*/
}
.banner-top {
  bottom: -8em;
  width: 100%;
}
}
@media (max-width:640px){
  .banner-top {
  margin: 2em 0px 0px;
  position: inherit;
}
.banner-text {
  float: none;
  width: 100%;
  margin-bottom: 1em;
}
}

@media (max-width:480px){
  .banner-top {
  bottom: -2em;
  width: 100%;
  position: inherit;
}
.banner-text_grid {
  padding: 1em 2em;
}

.banner-text {
  width: 100%;
  margin-bottom: 2em;
}

}

.constr-bg-color-2{
  background-color: #262626;
}

.promo-bg-color{
  background-color: #f9be1a;
}

.consult-item .icon i{
    font-size: 40px;
    color: #f44336;
}

.consult-item .title h4 {

    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #c5c5c5;

}

.consult-item .desc {
 
    font-weight: 300;
    font-size: 16px;
    color: #f1f1f1;
    padding-bottom: 25px;
}

.consult-cover .desc{
  padding-right: 60px;
}

.consult-item .icon {
    padding:0 0 10px 0;
}

.consult-cover-bg-color{
  background-color: #343a46;
}

.border-black { border-color:#ffd460 !important}





.const-abt-us{
  background: #f5f5f5;
  padding-top: 250px;
  padding-bottom: 50px;
}


.const-abt-title{

  font-weight: 200;
  font-size: 38px;
  text-transform: none;
  text-align: right;
  padding-right: 25px;
  border-right: 2px solid #deaa86;
  line-height: 1.3em;
}

.const-abt-title span{

  font-weight: 600;
  font-size: 38px;
  text-transform: none;
  text-align: left;
  color: #2c3942;

}

.const-abt-title-2{

  font-weight: 200;
  font-size: 38px;
  text-transform: none;
  text-align: right;
  padding-right: 25px;
  border-right: 1px solid #deaa86;
  line-height: 1.3em;
}

.const-abt-title-2 span{
 
  font-weight: 600;
  font-size: 38px;
  text-transform: none;
  text-align: left;
  color: #41404e;

}

.const-abt-p{

  font-weight: 300;
  font-size: 16px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 60px;
}


.const-abt-desc{

  font-weight: 300;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 30px;
}

.const-sign{
  text-align: right;
  padding-top: 20px;
  padding-right: 30px;
}


.const-srv-title{
  padding-top: 60px;

  font-weight: 400;
  font-size: 32px;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 30px;
  padding-left: 80px;
  color: #707070;
}


.const-srv{
 padding: 30px 8% 0;
}

.const-img-border-small-fix {  border: 5px solid rgba(255, 255, 255, 0.5);
 height:90%;
  width: 90%;
  left: 6%; top: 6%;
  position: absolute; }


 .consult-our-work h4 {
  padding-top: 70px;

  font-weight: 700;
  color:rgba(249, 183, 1,.8);
  font-size: 50px;
  padding-right: 5px;
  text-align: left;
  padding-bottom: 10px;
}

 .consult-our-work .right h4{ text-align: right; }

@media(min-width: 480px){
  .consult-our-work-item{
    margin-left: 20px;
  }
}
@media(min-width: 640px){
  .consult-our-work-item{
    margin-left: 20px;
  }
}
@media(min-width: 768px){
  .consult-our-work-item{
    margin-left: 0px;
  }
}
@media(min-width: 1024px){
  .consult-our-work-item{
    margin-left: 0px;
  }
}


.consutl-wrk-prcs-t{
  
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 5px;
  color: #2c2c2c;

}

.consutl-wrk-prcs-t-2{
  
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  text-align: right;
  padding-top: 10px;
  padding-bottom: 5px;
  color: #2c2c2c;

}

.consutl-wrk-prcs-d{

  font-weight: 300;
  font-size: 17px;
  text-align: left;
  padding-bottom: 5px;
  padding-right: 80px;

}

.consutl-wrk-prcs-d-2{

  font-weight: 300;
  font-size: 17px;
  text-align: right;
  padding-bottom: 5px;
  padding-left: 80px;

}


.consult-right-pic-1{
  width: 100%;
  height: 355px;
  background: url(../images/construct/rp-1.jpg) no-repeat;
  background-size: cover;
}

.consult-right-pic-2{
  width: 100%;
  height: 355px;
  background: url(../images/construct/rp-2.jpg) no-repeat;
  background-size: cover;
}

.consult-left-pic{
  width: 100%;
  height: 355px;
  background: url(../images/construct/lp-1.jpg) no-repeat;
  background-size: cover;
}


.const-img-overlay{
  position: absolute;
    top: 70%;
    bottom: 0%;
    left: 10%;
    padding: 1em;
    width: 81%;
    height: 100%;
}

.const-img-overlay-st{
  padding-bottom: 5px;
   
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    text-transform: none;
}
.const-img-overlay-t  {
  padding-bottom: 20px;
    
    font-weight: 400;
    font-size: 31px;
    color: #fff;
    text-transform: capitalize;
}





/*CONSULTANCY-CONTACT PAGE*/

.const-contact{
  background-color: #f8f8f8;
  padding-top: 120px;
  padding-bottom: 60px;
}

.consult-abt .content-md{
  padding-bottom: 50px;
}

.consult-work-proc{
  padding-top: 40px;
}


/* =-=-=-=-=-=-= Construction home-5 =-=-=-=-=-=-= */

.business-carousel-overlay{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(40,40,40,.6);
    -webkit-backface-visibility: hidden;

}
.biz-news{
  padding-top: 40px;
}
.business-abt{
  height: 550px;
}
.business-abt .col-md-6{
  height: inherit;
}
.business-abt .col-md-6 .fit-img{
  height: 550px;
}
.text-brown-1{
  font-family: 'Poppins', sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 75px;
  text-align: left;
  margin-left: 50px;
  text-transform: none;
}

.text-brown-1 span{
  font-family: 'Poppins', sans-serif;
  font-size: 67px;
  font-weight: 700;
  text-align: left;
  text-transform: none;
  color: #ff7f7f;
}

.features-section-agency{
  background-color: #f5f5f5;
 
}

.features-section-agency .title {
  
  font-weight: 700;
  font-size: 25px;
  text-align: left;
  letter-spacing: 1px;
  line-height: 70px;
  margin: 0 0 37px 0;
  text-transform: uppercase;
  color: #302d40;
}

.sub-title-overlay {

  font-weight: 700;
  position: absolute;
  color: rgba(249,183,1, 0.4);
}
.sub-title-overlay.counter {
  font-size: 88px;
  line-height: 70px;
}
.sub-title-overlay .icon {
  font-size: 65px;
}
.features-image img {
  position: absolute;
  height: 100%;
  max-height: 1300px;
  width: auto;
  top: 0;
  left: 0;
}
.features-item {
  counter-reset: section;
}
.features-item .each-features .inner {
  margin-bottom: 45px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.features-item .each-features:last-child .inner {
  margin-bottom: 0px;
}
.features-item .each-features .sub-title-overlay.counter::before {
  content: counter(section);
  counter-increment: section;
}
.features-item .each-features:nth-child(-n+9) {
  /* 1 - 9 */
}
.features-item .each-features:nth-child(-n+9) .sub-title-overlay.counter::before {
  content: "0" counter(section);
  /* becomes 01 - 09 */
}
@media (min-width: 1199px) {
  .features-item.three-col .each-features:nth-last-child(-n + 3) .inner {
    margin-bottom: 0px;
  }
}
@media (min-width: 767px) {
  .features-item.three-col .each-features:nth-last-child(-n + 2) .inner {
    margin-bottom: 0px;
  }
}
@media (min-width: 1199px) {
  .features-item.two-col .each-features:nth-last-child(-n + 2) .inner {
    margin-bottom: 0px;
  }
}
@media (min-width: 767px) {
  .features-item.two-col .each-features:nth-last-child(-n + 1) .inner {
    margin-bottom: 0px;
  }
}

/* Construction about-3/creative*/

.dig-slider{
  height: 510px;
}
.dig-slider .col-md-6{
  height: inherit;
}
.dig-slider .col-md-6 .fit-img{
  height: 510px;
}

.dig-img-overlay{
  position: absolute;
    top: 0%;
    left: 10%;
    padding: 1em;
    width: 81%;
    height: 100%;
}

.dig-img-overlay-t {
  padding-top: 10px;
  padding-bottom: 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 40px;
    text-transform: uppercase;
    color: #000000;
    text-transform: none;
}

.dig-img-overlay-st {
    padding-top: 5px;
   padding-bottom: 10px;

    font-weight: 400;
    font-size: 25px;
    color: #000000;
    text-transform: none;
}

.dig-agency-logo{
  padding-top: 100px;
}

.dig-agency-logo img{
  width: 100px;
  height: auto;
}



.avatar-1{
  background: url(../images/intro-img1.jpg) no-repeat center fixed;
    -webkit-background-size: cover;
            background-size: cover;
}

.avatar-2{
  background: url(../images/intro-img2.jpg) no-repeat center fixed;
    -webkit-background-size: cover;
            background-size: cover;
}

.intro-abt {
  text-align: left;
  background-color: #f4f4f4;
  position: relative;
}
.intro-abt  blockquote {
  border: 0;
  margin: 0;
  padding: 30px 15% 0;
}
.intro-abt h1 {
  color: #000;

  font-weight: 400;
  font-size: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.intro-abt  p {
  color: #000;

  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  margin-top: 20px;
}
.no-gutter [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}



.img-border-small-fix {  border: 5px solid rgba(255, 255, 255, 0.5);
 height:90%;
  width: 90%;
  left: 5%; top: 5%;
  position: absolute; }

.border-gray { border-color:rgba(249, 183, 1,.4)!important}

.border-gray-1 { border-color:rgba(197, 197, 197,.7)!important}


/*Clients Style -2*/
.clients.plus-box li:before {
    height: 100%;
    top: 0;
    right: -2px;
    border-right: 1px solid #DDD;
}
.clients.plus-box li:after  {
    width: 100%;
    height: 0;
    top: auto;
    right: 0;
    bottom: -1px;
    border-bottom: 1px solid #DDD;
}
.clients.plus-box li:after,
.clients.plus-box li:before,
.clients.angle-box li:after,
.clients.angle-box li:before {
    content: '';
    position: absolute;
}






/* =-=-=-=-=-=-= [Startup Agency]=-=-=-=-=-=-= */

.slider-section {
  height: 100vh;
  -webkit-background-size: cover;
            background-size: cover;
}

.slider-overlay{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(26,34,39,0.4);
    -webkit-backface-visibility: hidden;
}

.tt-carousel .carousel-inner{
  min-height: 590px;
}

.carousel-indicators li {
    margin: 0;
    width: 8px;
    height: 8px;
    border: 1px solid #282828;
    border-radius: 20px;
    margin-bottom: 7%;
}

 .carousel-indicators .active {
    margin: 0;
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 20px;
    margin-bottom: 7%;
  }

.text-black-4{

  font-size: 90px;
  letter-spacing: 6px;
  color: #000;
  font-weight: 200;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.content-slider-subtitle{
  text-transform: uppercase;
  font-size: 20px;
  color: #000;
  letter-spacing: .8em;
}


@media (min-width: 992px) and (max-width: 1199px) {
    .carousel-intro {
        padding-top: 100px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .tt-carousel .carousel-inner {
        min-height: 430px;
    }
    .carousel-intro {
        padding-top: 100px;
    }
    .carousel-intro h1 {
        font-size: 25px;
    }
    .carousel-intro h2 {
        font-size: 38px;
    }
    .layer-two {
        padding-top: 70px;
    }
}
@media (max-width: 767px) {
    .tt-carousel .carousel-inner {
        min-height: 450px;
    }
    .carousel-intro {
        padding-top: 80px;
    }
}
@media (max-width: 530px) {
    .carousel-intro h1 {
        font-size: 22px;
        margin-bottom: 0;
    }
    .carousel-intro h2 {
        font-size: 30px;
    }
    .carousel-intro p {
        font-size: 14px;
        line-height: 20px;
    }
}


/* =-=-=-=-=-=-=Concord AGENCY-1 =-=-=-=-=-=-= */


.text-blue{
  color: #ffffff;
  font-size: 70px;
  letter-spacing: 1px;

  font-weight: 100;
  margin-left: 50px;
  padding-bottom: 20px;
  text-align: left;

}

.text-blue span{
  color: #0099ff;
  font-size: 75px;

  font-weight: 400;
  text-align: left;
}


.text-blue-sub{
  color: #0099ff;
  margin-left: 50px;

}



/* =-=-=-=-=-=-=Concord AGENCY-2 =-=-=-=-=-=-= */




.agency-2.services .col-md-6{
  padding-left: 0px;
  padding-right: 0px;
}

.agency-logo-2{
  padding-top: 60px;
}

.agency-logo-2 img{
  width: 50px;
  height: auto;
}

.ag-img-overlay-t  {
  padding-top: 10px;
  padding-bottom: 10px;

    font-weight: 400;
    font-size: 30px;
    color: #0b303c;
    text-transform: uppercase;
}
.ag-img-overlay-st  {
  padding-bottom: 20px;

    font-weight: 200;
    font-size: 23px;
    color: #0b303c;
    text-transform: uppercase;
}



.agency-2-item .icon {
    padding:0 0 10px 0;
}


.agency-2-item .icon i{
    font-size: 35px;
    color: #2c2c2c;
}

.constr-bottom-border{

    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    display: inline-block;

}




/* =-=-=-=-=-=-=IMAGE BG =-=-=-=-=-=-= */

.text-black{

  color: #000;
  font-size: 150px;
  font-weight: 100;
  letter-spacing: 9px;
}





.dark-image-bg{
  -webkit-background-size: cover;
            background-size: cover;
}



.image-color-overlay-2 {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0,0,0,0.2);
    -webkit-backface-visibility: hidden;
}


/* =-=-=-=-=-=-= Creative Agency-1=-=-=-=-=-=-= */
.kenburn-overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0,0,0,0.5);
    -webkit-backface-visibility: hidden;
}

.kenburn-wrap{
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 100vh;
}
.text-white-5{

  color: #f44336;
  text-transform:uppercase;
  text-shadow: 1px 1px #000;
  font-size: 90px;
  font-weight: 400;
  letter-spacing: .1em;
  margin-bottom: 30px;

}
.kenburn-sub-intro{
  letter-spacing: 3px;
  text-transform: uppercase;

  padding: 10px;
}






/* =-=-=-=-=-=-= Resturant=-=-=-=-=-=-= */

.rest-intro-img{
  width: 100%;
}

.carousel-inner{
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 100vh;
}

.carousel-inner .item img{
  width: 100%;
  height: 100%;
}

.full-screen {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.carousel-color-overlay{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0, 0, 0,0.5);
    -webkit-backface-visibility: hidden;

}

.text-white-6{

  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  color: #FFFFFF;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -2px;
}

/*Resturant*/


.resturant-gal .post-thumbnail{
  margin-bottom: 0px;
}



.res-chef-spcl{
/*    background: url(../images/resturant/chef-spl.jpg) no-repeat center fixed;*/
    -webkit-background-size: cover;
            background-size: cover;
    color: #ffffff;
}


.chef-color-overlay {
    background: rgba(0, 0, 0, .4);
    padding-top: 20px;
    padding-bottom: 20px;
}

.res-chef-title  {
    height: 400px;
    display: table;
    vertical-align: middle;
    width: 100%;
}
.res-chef-title h3 {
  
    color: #ffffff;
    font-size: 40px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    letter-spacing: 3px;
    text-transform: capitalize;
}

.chef-sub-title{
  padding-top: 150px;

  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}

.chef-title{
  padding-top: 10px;

  font-weight: 200;
  font-size: 27px;
  letter-spacing: 3px;
  text-align: left;
  text-transform: uppercase;
}


.res-branch{

  padding-bottom: 50px;
}

.res-bg-color{

  background: linear-gradient(270deg, #f4dac2, #fdf6f0);
background-size: 400% 400%;

-webkit-animation: AnimationName 30s ease infinite;
-moz-animation: AnimationName 30s ease infinite;
animation: AnimationName 30s ease infinite;

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

}


/* =-=-=-=-=-=-= CONSTRUCTION=-=-=-=-=-=-= */

.feature-top{
  padding-top: 10px;
}

.text-white-4{

  color: #FFFFFF;
  font-size: 50px;
  padding-bottom: 10px;
  font-weight: 700;
  letter-spacing: 3px;
  padding-top: 70px;
  text-transform: uppercase;
}

.constr-color-overlay{
  height: 100vh;
  background: rgba(0, 0, 0, .6);
}

.constr-home-item{
  padding-top: 130px;
}

.dn-title-3{
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
}

.construct-project .pf-filter li:hover{
  color: #f9b701;
}
.construct-project .pf-filter li.active{
  color: #f9b701;
}

.construct-project #clients-1{
  padding-bottom: 60px;
}

.constr-about-title{

  font-weight: 200;
  font-size: 38px;
  text-transform: none;
  text-align: left;
  padding-right: 25px;

  line-height: 1.3em;
}


.constr-page-image-parallax{
   width: 100%;
  height: 500px;
  padding-bottom: 20px;

}

.constr-page-overlay{
  background-color: rgba(0,0,0,.6);
}

.constr-p-t{
  padding-top: 250px;
  padding-bottom: 10px;
  line-height: 1em;
  font-weight: 700;
  font-size: 55px;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: #ffffff;
}

/*service*/
.constr-bg-color{
  background-color: #f4f4f4;
}

.constr-home-abt{
  font-weight: 300;
  font-size: 16px;
  padding-bottom: 60px;
}
.constr-abt {
    margin-bottom: 20px;
    overflow: hidden;
}
.constr-abt-image-1 {
  width: 60%;
  position: relative;
  float: right;
  padding: 20px 0px;
}
.constr-abt-image-2 {
  width: 50%;
  position: absolute;
  top: 18%;
  left: 15%;
  border: 5px solid #f9b701;
}

@media screen and (min-width: 480px) {
  .constr-abt-image-2 {
    width: 50%;
    position: absolute;
    top: 45%;
    left: 15%;
    border: 5px solid #f9b701;
  }
}

@media screen and (min-width: 640px) {

  .index-about-grid {
    padding: 0;
  }
  .constr-abt-image-2 {
    top: 40%;
    left: 5%;
  }
}

@media screen and (min-width: 768px) {
  .index-about-image {
    padding-bottom: 125px;
  }
  .constr-abt-image-1 {
    width: 60%;
    top: 20px;
    height: auto;
    left: 30%;
  }
  .constr-abt-image-2 {
    top: 20%;
    left: 30%;
    width: 50%;
    height: auto;
  }
  }
  @media screen and (min-width: 1024px) {
    .constr-abt-image-1 {
    width: 60%;
    top: 20px;
    height: auto;
    left: 0;
    }
  .constr-abt-image-2 {
    top: 20%;
    left: 10%;
    width: 50%;
    height: auto;
  }
}


.constr-abt-subtitle{
  font-size: 18px;

  font-weight: 400;
  text-transform: capitalize;
  text-align: left;
  font-style: italic;
}

.constr-abt-title{

  font-size: 17px;
  text-align: left;
  text-transform: uppercase;
}

.constr-abt-desc{
  text-align: left;
  padding-bottom: 60px;
}

.constr-abt-us-title{
  
  font-weight: 400;
  font-size: 22px;
  text-align: left;
  text-transform: uppercase;
}

.constr-abt-us-subtitle{
  
  font-weight: 400;
 letter-spacing: 2px;
  font-size: 13px;
  text-align: left;
  text-transform: uppercase;
}

.constr-under-line{
    /*margin: auto;*/
    z-index: 1;
    width: 55px;
    height: 2px;
    background: #000;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 30px;
}

.constr-under-line-2{
    margin: auto;
    z-index: 1;
    width: 55px;
    height: 1.5px;
    background: #fff44f;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.constr-service{
/*  padding-top: 100px;*/
  padding-bottom: 60px;
}

.constr-service-2{
  padding-top: 80px;
  padding-bottom: 30px;
}

.constr-ftr-subtitle{

  font-size: 18px;
  font-weight: 600;
  padding-top: 200px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.constr-ftr-title{
  font-size: 26px;
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: #2c2c2c;
}

.constr-ftr-desc{
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}



/*Skill*/
.constr-skills{
  padding-top: 80px;
  padding-bottom: 50px;
}

.constr-skill-chart{
  padding-top: 30px;
}

.constr-item .icon {
    padding:0 0 10px 0;
}

.constr-why-bg-color{
  background-color: #2c2c2c;
}
.constr-why-bg-color-2{
  background-color: #2c3942;
}

.border-black { border-color:#f9b701;}

.constr-item .icon i{
    font-size: 40px;
    color: #fab702;
}

.constr-item-2 .icon i{
    font-size: 40px;
    color: #f9b701;
}

.constr-item-3 .icon i{
    font-size: 40px;
    color: #deaa86;
}

.constr-bottom-border{

    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    display: inline-block;

}

.constr-item .title h4 {
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #fcfcfc;

}

.constr-item .desc {
    font-weight: 600;
    font-size: 15px;
    color: #8d8d8d;
    padding-bottom: 25px;
}

.constr-item-2 .title h4 {
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #deaa86;

}

.constr-item-2 .desc {
    font-weight: 600;
    font-size: 15px;
    color: #8d8d8d;
    padding-bottom: 25px;
}


.constr-item-3 .title h4 {
    font-family: "Lato",sans-serif;
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #deaa86;

}

.constr-item-3 .desc {
    font-weight: 600;
    font-size: 15px;
    color: #8d8d8d;
    padding-bottom: 25px;
}

.const-choice .desc{
  padding-right: 60px;
}

.tab-head {

}
.tabs li a {
    font-size: 17px;
    padding: 10px;
    font-weight: 400;
    color: #777777;
    border-right:3px solid #656565;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.tabs li a span{
   margin-right: 1em;
}
.tabs  li a:hover,.tabs  li.active a{
  border-right:3px solid  #f9b701 !important;
      color: #000!important;
}
.tab-content {
    padding-left: 0px;
}
.facts{
    text-align: left;
}

.facts h4 {
   color: #000;
 text-transform: uppercase;
 text-align: left;
  font-size: 24px;
  font-weight: 400;
}
.facts p {
    color:#8A8A8A;
    font-size: 1em;
    line-height: 2em;

    text-align: left;
}

.constr-icon{
  font-size: 38px;
  color: #ffd02a;
}

.constr-bg-cl{
  background-color: #f5f5f5;
}

.services-list {
    padding: 0px 0;
}
.services-gds{
  margin-top:15px;
  background-color: #ffffff;
}
.list-gds span{
  color:#C19A04;
  font-size:30px;
}
.list-gds:nth-child(2),.list-gds:nth-child(5){
  border-left:1px solid #2e2f39;
  border-right:1px solid #2e2f39;
}
.list-gds:nth-child(6),.list-gds:nth-child(4),.list-gds:nth-child(5){
  border-top:1px solid #2e2f39;
}
.list-gds p {
    font-size: 16px;
    font-weight: 600;
    line-height: 2.2em;
    color: #707070;
}
.list-gds h4 {
  font-weight: 700;
  text-transform: uppercase;
    color: #282828;
    font-size: 23px;
    margin: 25px 0 15px;
    letter-spacing: 1px;
}
.list-gds {
    padding: 30px 16px;
    transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;
}
.list-gds:hover{
    background: url(../images/pic11.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    -moz-background-size: cover;
  transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;  
}
.list-gds:hover.list-gds h4{
  color:#fff;
    transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;
}
.list-gds:hover.list-gds p{
  color:#fff;
    transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;
}


.list-gds-2 span{
  color:#C19A04;
  font-size:30px;
}
.list-gds-2:nth-child(2),.list-gds:nth-child(5){
  border-left:1px solid #2e2f39;
  border-right:1px solid #2e2f39;
}
.list-gds-2:nth-child(6),.list-gds:nth-child(4),.list-gds:nth-child(5){
  border-top:1px solid #2e2f39;
}
.list-gds-2 p {
    font-size: 16px;
    font-weight: 600;
    line-height: 2.2em;
    color: #707070;
}
.list-gds-2 h4 {
  font-weight: 700;
  text-transform: uppercase;
    color: #282828;
    font-size: 23px;
    margin: 25px 0 15px;
    letter-spacing: 1px;
}
.list-gds-2 {
    padding: 30px 16px;
    transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;
}
.list-gds-2:hover{
    background: url(../images/pic12.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    -moz-background-size: cover;
  transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;  
}
.list-gds-2:hover.list-gds-2 h4{
  color:#fff;
    transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;
}
.list-gds-2:hover.list-gds-2 p{
  color:#fff;
    transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;
}



/*--Feature projects -2 [Lazy Owl]--*/
.project {
  padding: 0em 0px 5px 0px;
}
.project-name {
  width: 54%;
  text-align: left;
  background: #e21d39;
  /*padding: 8px 0 8px 28px;*/
  padding: 19px 10px 9px 33px;
  bottom: 0;
  left: 46%;
  position: absolute;
  text-transform: uppercase;
}
.project-name p {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.2em;
  text-align: center;
  
}
.project-name span{
  position: absolute;
  width:30px;
  height:30px;
  display:block;
  top: 24px;
  left: -16px;
  cursor: pointer;
}
/*.item:hover div.project-name{
  background: #ebb30a;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}*/
#owl-demo .item {
  position: relative;
}

/*Construct-Service Page*/


.constr-funfactor {
    background: #ffffff;
    -webkit-background-size: cover;
            background-size: cover;

}

.constr-numbers{
  font-size: 45px;
  font-weight: 700;
  color: #ffd460;
}

.constr-funfactor .fun-info {
  color: #000;
  padding-bottom: 20px;
}

.constr-funfactor .icon-container {
    display: block;
    margin-top: 5px;
}

.constr-funfactor .icon-container i {
    font-size: 30px;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}

.constr-funfactor h2 {
    line-height: 2rem;
}

.constr-funfactor .fun-info .fun-work{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
}

.constr-srv-details{
  padding-left: 50px;

}
.constr-srv-details .service-covers p{
  padding-left: 15px;
}
.constr-srv-img{
  padding-top: 80px;
  padding-bottom: 30px;
}
.constr-srv-img-2{
  padding-left: 10px;

  padding-top: 20px;
  padding-bottom: 15px;
}

.constr-srv-feature{

  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;

}

.constr-srv-feature-title{
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.portfolio-project{
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 60px;
}

.portfolio-project-heading{
  text-transform: uppercase;
  text-align: left;
  padding-top: 120px;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #bcc6cc
}
.case-study-heading{
  text-transform: uppercase;
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #282828;
  padding-bottom:30px;
}
.case-study-title {
  padding-top: 35px;
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  color: #bcc6cc
}

.case-study-details{
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.8;
  padding-bottom: 50px;

}

.case-study-info {
    /*margin-left: 80px;*/
    list-style: none;
    padding-top: 80px;
    text-align: left;

}

.case-study-info li {
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 15px 0;
    color: rgba(0,0,0,.7);

}

.case-study-info li span {
  font-family: monospace;
    color: #464646;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 2px;
}


/*Construction About Page*/

.constr-work-process {
  background-image: url(../images/construct/bg-3.jpg);
  height:400px;
}

.constr-work-process .overlay {
  padding-top: 80px;
  height:400px;
  background-color:rgba(44,62,80, 0.90);
  /*z-index: 100;*/
}

.process-number {
  position: relative;
  top: 20px;
  left: 40%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.constr-prcs-title{
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 400;
  color: #f9b701;
}

.constr-prcs-desc{
  font-size: 16px;
  font-weight: 300;
  color: #fefefe;

}


@media(min-width: 480px){
  .constr-work-process {
    height: 800px;
  }
  .constr-work-process .overlay {
    height: 800px;
  }
}
@media(min-width: 640px){
  .constr-work-process {
    height: 700px;
  }
  .constr-work-process .overlay {
    height: 700px;
  }
}
@media(min-width: 768px){
  .constr-work-process {
    height: 600px;
  }
  .constr-work-process .overlay {
    height: 600px;
  }
}
@media(min-width: 1024px){
  .constr-work-process {
    height: 400px;
  }
  .constr-work-process .overlay {
    height: 400px;
  }
}

/* =-=-=-=-=-=-= PRODUCT LANDING=-=-=-=-=-=-= */




/* =-=-=-=-=-=-= Agency- STUDIO=-=-=-=-=-=-= */

.text-white-3{
  color: #FFFFFF;
  font-size: 100px;
  padding-bottom: 20px;
  font-weight: 100;
  letter-spacing: 0.1em;
}



.studio-features-section .section-header {
  margin-top: 30px;
  margin-bottom: 0px;
}
.btn-video .btn {
  min-width: 0;
  width: 115px;
}

.studio-overlay-color {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
}

.section-separator {
  display: block;
  padding-top: 65px;
  padding-bottom: 15px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.studio-section-heading {
  color: rgba(255, 255, 255, 0.9);
  font-size: 35px;
  font-weight: 200;
  display: inline-block;
  padding-bottom: 13px;
  margin: 0;
}
.studio-section-header {
  margin-bottom: 60px;
  text-align: center;
}
.studio-section-header .sub-heading {
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  color: rgba(248, 248, 248, 0.9);
  margin: 0;
}



/* ==================================================================
 SERVICE SECTION
================================================================== */

.services {
    margin: 0 auto;
}

.services .single-service {
    margin: 10px;
    padding: 15px;
    border: 1px solid rgba(125,125,125,0.3);
    min-height: 165px;
}

.services .single-service h5 {
   padding-bottom: 10px;
   font-size: 15px;
   font-weight: 400;
   text-transform: uppercase;
   letter-spacing: 3px;
   line-height: 14px;
}
.services .single-service p {
   text-align: center;
}


.services .single-service .icon-container {
    display: block;
    min-height: 60px;
    margin-top: 5px;
    padding-top: 5px;
}

.services .single-service .icon-container .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 55px;
    font-size: 24px;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}

/*Service icon hover effect animation*/

@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

.hvr-ripple-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
.hvr-ripple-out:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.hvr-ripple-out:hover:before, .hvr-ripple-out:focus:before, .hvr-ripple-out:active:before {
  -webkit-animation-name: hvr-ripple-out;
  animation-name: hvr-ripple-out;
}

/* =-=-= Service Business =-=-= */

.serv-block{
  position: relative;
    width: 100%;
    overflow: hidden;
  margin-bottom: 10px;
}

.serv-block-title{
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #282828;
  padding-bottom: 5px;
}

.featured-box .one-third {
    /*width: 25%;*/
    float: left;
    padding: 0px 0px;
}

.featured-box .one-third:nth-child(2) {
    padding: 0px 0px;
}


.featured-box .one-third .featured-item {
    padding: 10% 15%;
    min-height: 260px;
}

.featured-box .one-third .featured-item,
.featured-box .one-third .featured-item h4,
.featured-box .one-third .featured-item .desc{
    color: #fff;
}

.color-1 {
    background: #718c9e ;
}

.color-2 {
    background: #a89080;
}

.color-3 {
    background: #719e9a;
}

.color-4 {
    background: #a88085;
}

.height-fix {
    height: 300px;
}


.strt-up-button{
  margin-left: 60px;
}

.creame-bg{
  background: #f6f3f1;
}

.funfactor.strt-up .color-overlay{
  padding-top: 50px;
}
.funfactor.strt-up .icon-container i{
  color: #7198ab;
}
.featured-item .icon {
    padding:0 0 10px 0;
}

.featured-item .icon i{
    font-size: 40px;
}

.featured-item .title h4 {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 3px;
    text-transform: uppercase;

}

.featured-item .desc {
    /*color: #f5f5f5;*/
    padding-bottom: 5px;
    /*padding-left: 25px;
    padding-right: 25px;*/
}

.featured-item.border-box {
    border: 1px solid #efefef;
    padding: 40px;
    margin-left: -1px;
    margin-bottom: -1px;
}

.featured-item.border-box:hover {
    border: 1px solid transparent;

}

.featured-item.border-box:hover h4 {
    color: #fff;
}

.featured-item.border-box,
.featured-item.border-box:hover,
.featured-item.border-box h4,
.featured-item.border-box:hover h4,
.featured-item.border-box .icon i
.featured-item.border-box:hover .icon i{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.feature-box-grid .col-md-6{
    padding-left: 0px;
    padding-right: 0px;

}
.feature-box-grid .featured-item{
    text-align: center;
}



.featured-item-2 .icon {
    padding:0 0 10px 0;
}

.featured-item-2 .icon i{
    font-size: 50px;
    color: #f12e4c;
}

.featured-item-2 .title h4 {
    font-weight: 400;
    font-size: 26px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1b2943;

}

.featured-item-2 .desc {
    color: #515151;
    padding-bottom: 5px;
}


/* ==================================================================
  PORTFOLIO SECTION
================================================================== */
.isotope {
  max-width: 100%;
}

/* clear fix */
.isotope:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .item ---- */

.item {
  float: left;
  width: 100%;
  height: auto;
}

.item.width2 { width: 200%; }
.item.height2 { height: auto; }


* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

#portfolio .container{
  /*width: 100%;*/
}

/* ----GRID---- */

 .portfolio-button-group{

    padding-bottom: 45px;
  }

/* ========== Portfolio Filter ========== */
.pf-filter {
  margin-bottom: 40px;
  text-align: center;
  overflow: hidden;
  cursor: default;
}
.pf-filter-page {
  margin-top: -20px;
}
.pf-filter li {
  display: inline-block;
  height: 34px;
  margin: 0 15px 10px;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 400;
  line-height: 34px;
  text-transform: uppercase;
  color: #000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color 160ms linear;
  -moz-transition: border-color 160ms linear;
  -o-transition: border-color 160ms linear;
  transition: border-color 160ms linear;
}
/*.pf-filter li.active{
  color: #9E4147;
}*/

@media screen and (-webkit-min-device-pixel-ratio: 0){
.pf-filter li {
  line-height: 36px;
}}
.pf-filter li:hover {
  cursor: pointer;
}
.active-filter .line-top,
.active-filter .line-bottom{
  width: 100%;
}
.active-filter .line-left,
.active-filter .line-right{
  height: 100%;
}
/* ========== Button - Lines ========== */
.btn-lines {
  position: relative;
}
.btn-lines .line-top,
.btn-lines .line-bottom,
.btn-lines .line-left,
.btn-lines .line-right {
  position: absolute;
  background: #666;
  -webkit-transition: width 210ms ease-out 10ms, height 210ms ease-out 10ms;
  -moz-transition: width 210ms ease-out 10ms, height 210ms ease-out 10ms;
  -o-transition: width 210ms ease-out 10ms, height 210ms ease-out 10ms;
  transition: width 220ms ease-out 10ms, height 220ms ease-out 10ms;
}
.btn-lines .line-top,
.btn-lines .line-bottom {
  width: 0;
  height: 1px;
}
.btn-lines .line-top {
  top: 0;
  left: 0;
}
.btn-lines .line-bottom {
  bottom: 0;
  right: 0;
}
.btn-lines .line-left,
.btn-lines .line-right {
  width: 1px;
  height: 0;
}
.btn-lines .line-left {
  bottom: 0;
  left: 0;
}
.btn-lines .line-right {
  top: 0;
  right: 0;
}
.btn-lines:hover .line-top,
.btn-lines:hover .line-bottom {
  width: 100%;
}
.btn-lines:hover .line-left,
.btn-lines:hover .line-right {
  height: 100%;
}


/* EVEN GRID */
.grid {
  margin: 0px;
  max-width: 100%;
  text-align: center;
}

.grid:after {
  content: '';
  display: block;
  clear: both;
}


.grid-item {
  width: 100%;
  height: auto;
  background: #FFFFFF;
}

.grid-item-gut{
  width: 95%;
  height: auto;
  background: #FFFFFF;
}

/*3 COLUMN MASSONRY*/

.grid-masonry{
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
}

.m-grid-item {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  padding: 5px;
}

.m-grid-item--height2 { height: 100%; }


/* 4 COLUMN MASSONRY*/

.m-grid-item-2 {
  width: 100%;
  height: 100%;
  background: #FFFFFF;


}
.m-grid-item-2--height2 { height: 100%; }

.masonry-pad{
  padding: 5px;
}

/* Double width masonry */
/*.m-grid-item-2--width2 { height: 100%; }*/


/*PORTFOLIO HOVER EFFECT*/

.portfolio-hover {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.masonry-grid-3 .portfolio-hover,
.masonry-grid-4 .portfolio-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.portfolio-hover .action-btn {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    -webkit-transform: translateY(45%);
    -ms-transform: translateY(45%);
    transform: translateY(45%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    font-size: 25px;
}

.portfolio-hover .action-btn a {
  color: #000;
}

.portfolio-hover .action-btn .port-title{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.portfolio-hover .action-btn .port-subt{
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.similar-project .portfolio-hover{
  position: absolute;
  top: 0;
  left: 5%;
  width: 95%;
  height: 95%;
  margin: 0;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}


.grids-item:hover .portfolio-hover,
.grids-item:hover .portfolio-hover .portfolio-description,
.grids-item:hover .portfolio-hover .action-btn {
    opacity: 1;
}


.grids-item:hover .portfolio-hover .portfolio-description {
    bottom: 45%;
    margin-bottom: -90px;
}

.grids-item:hover .portfolio-hover .action-btn {
    top: 50%;
    margin-top: -55px;
}



/* ==================================================================
  PORTFOLIO SINGLE PROJECT PAGE
================================================================== */
.portfolio-project-title{


}
.portfolio-back-link{
  padding-top: 12%;
}
.portfolio-image-parallax{
   width: 100%;
  height: 350px;
  /*position: relative;*/
  padding-bottom: 20px;

}

.portfolio-image-parallax-2{
   width: 100%;
  height: 500px;
  /*position: relative;*/
  padding-bottom: 20px;

}

.project-color-overlay{
  background-color: rgba(44,44,44,.6);
}

.portfolio-project-heading{
  text-transform: uppercase;
  text-align: left;
  padding-top: 120px;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #bcc6cc
}

.portfolio-project-title {
  padding-top: 20px;
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  color: #bcc6cc
}

.portfolio-project-details{
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.8;
  padding-bottom: 50px;

}

.portfolio-project-info {
    /*margin-left: 80px;*/
    list-style: none;
    padding-top: 80px;
    text-align: left;

}

.portfolio-project-info li {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 15px 0;
    color: rgba(0,0,0,.7);

}

.portfolio-project-info li span {
    color: #6d747c;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
}

.portfolio-project-info li a {
    font-size: 32px;
    color: #000;
}

.portfolio-project-info li a i{
  vertical-align: middle;
}

.portfolio-project-info li a:hover {
}



.portfolio-project-media{}


/* SIMILAR WORK WITH CAROSEL */


.similar-project{
  padding-top: 20px;
  padding-bottom: 120px;
}

.similar-project-title{
  padding-bottom: 50px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 30px 0 0 0;

}

.similar-project-grid-item {
  width: 300px;
  height: 250px;
  background: #FFFFFF;
  padding: 15px;

}


/* ==================================================================
  ABOUT US
================================================================== */


.about{
  background: #FFFFFF;
}

.about-us{
  text-align: left;
  font-size: 16px;
}

.about-descrp p{
  padding-bottom: 15px;
}

.about-us .col-md-6{
  padding: 0px 30px;
}

.about-subtitle{
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
}

.about-subtitle-2{
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
}

.about-us a {
  font-size: 17px;
  font-weight: 400;
  color: #000;
}

/* ACCORDION */

.transition, .accordion ul li p, .accordion ul li i:before, .accordion ul li i:after {
  transition: all 0.25s ease-in-out;
}

.flipIn, h3, .accordion ul li {
  animation: flipdown 0.5s ease both;
}

.no-select, .accordion ul li h4 {
  text-transform: none;
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.accordion ul li p {
  font-weight: 300;
  padding-top: 7px;
  font-size: 16px;
  color: #2c2c2c;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transform: translate(0, 0);
  z-index: 2;
}

.accordion ul {
  list-style: none;
  perspective: 900;
  margin: 0;
  padding-bottom: 10px;
}
.accordion ul li {
  position: relative;
  padding: 0;
  margin: 0;
}
.accordion ul li:nth-of-type(1) {
  animation-delay: 0.5s;
}
.accordion ul li:nth-of-type(2) {
  animation-delay: 0.75s;
}
.accordion ul li:nth-of-type(3) {
  animation-delay: 1s;
}
.accordion ul li:last-of-type {
  padding-bottom: 0;
}
.accordion ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 10px;
  right: 0;
  /*vertical-align: middle;*/
}
.accordion ul li i:before, .accordion ul li i:after {
  content: "";
  position: absolute;
  background-color: #000;
  width: 1px;
  height: 16px;
}
.accordion ul li i:before {
  height: 12px;
  transform: translate(1px, -1px) rotate(-45deg);
}
.accordion ul li i:after {
  height: 12px;
  transform: translate(1px, -1px) rotate(45deg);
}
.accordion ul li input[type=checkbox] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}
.accordion ul li input[type=checkbox]:checked ~ p {
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  transform: translate(0, 50%);
}
.accordion ul li input[type=checkbox]:checked ~ i:before {
  height: 8px;
  transform: translate(-6px, 10px) rotate(135deg);
}
.accordion ul li input[type=checkbox]:checked ~ i:after {
  height: 16px;
  transform: translate(-1px, 2px) rotate(-155deg);
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }
  5% {
    opacity: 1;
  }
  80% {
    transform: rotateX(8deg);
  }
  83% {
    transform: rotateX(6deg);
  }
  92% {
    transform: rotateX(-3deg);
  }
  100% {
    transform-origin: top center;
    transform: rotateX(0deg);
  }
}


/* ==================================================================
 TABS
================================================================== */

/* Tab Style -1 [ Corporate, Finance Agency strategy]*/

.nav-tabs {
 border-bottom: 1px solid #DDD;
text-transform: uppercase;}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover { border-width: 0; background: #dadada; }
.nav-tabs > li > a { border: none; color: #666; background:#6c6c6c;}


.nav-tabs > li.active > a, .nav-tabs > li > a:hover { border: none; opacity: 1; }
/*.nav-tabs > li > a::after { content: ""; background: #ffffff; margin-bottom: 5px; height: 1px; position: absolute; width: 100%; left: 0px; bottom: 0px; transition: all 250ms ease 0s; transform: scale(0); }*/
.nav-tabs > li.active > a::after, .nav-tabs > li:hover > a::after { transform: scale(1); }
/*.tab-nav > li > a::after { background: #21527d none repeat scroll 0% 0%; color: #fff; }*/
.tab-pane { padding: 5px 0; }
.tab-content{
padding:5px;
text-align: left;
}


/* ==================================================================
  PROGRESS BAR
================================================================== */
.progress {
    width: 80%;
    background: none repeat scroll 0 0 #F1F1F1;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    box-shadow: none;
    height: 3px;
    margin-bottom: 15px;
}
  .progress .bar {
    line-height: 30px;
    text-align: left;
    font-weight: bold;
    text-indent: 15px;
    text-transform:uppercase;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-text-shadow: none;
    -moz-text-shadow: none;
    text-shadow: none;
        -webkit-transition: width 1.5s ease;
        -moz-transition: width 1.5s ease;
        -o-transition: width 1.5s ease;
        transition: width 1.5s ease;
  }

  .progress:hover .bar {
    -webkit-transition: all 1s ease; -moz-transition: all 1s ease; -o-transition: all 1s ease; transition: all 1s ease;
    background: #2c3e50 !important;
    color:#fff;
    cursor: pointer;

  }

.progress-skill-bar {
  margin-bottom: 20px;
  margin-left:0;
  margin-top: 15px;
}
.progress-skill-bar li{
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;
    overflow: hidden;
}
.progress-skill-bar .label {
    float: right;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    border-right: 1px transparent solid;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 8px;
}

.progress-success .bar,
.progress .bar-success {
  filter: none;
  background: #15b994; }


.piechart-v2 {
  text-align: center;
}

.piechart-v2 .piechart-v2-title {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  color: #222222;
}

.piechart-v2 .circle-v2 .circles-text-v2 {
  font-weight: 600;
  font-size: 20px !important;
  color: #fff;
}



/* ==================================================================
  TESTIMONIAL
================================================================== */

.testimonial .container {
  width: 100%;
}


.testimonials {
    background: url(../images/bg-3.jpg) no-repeat center fixed;
    -webkit-background-size: cover;
            background-size: cover;
    color: #ffffff;
}


.testimonials .color-overlay {
    background: rgba(0, 0, 0, .7);
    padding-top: 70px;
    padding-bottom: 70px;
}

.testimonials .feedback {
    max-width: 750px;
    margin: 0 auto;
}

.testimonials .feedback .image {
    font-size: 24px;
    border: 2px solid transparent;
    -webkit-box-shadow: 0px 0px 0px 2px #ffffff;
            box-shadow: 0px 0px 0px 2px #ffffff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: auto;
    margin-top: 5px;
    overflow: hidden;
}

.testimonials .feedback .image img {
    width: 100%;
    vertical-align: top;
}

.testimonials .feedback .message {

    font-style: normal;
    margin-top: 20px;
    margin-bottom: 20px;
}

.testimonials .feedback .name {
    margin-top: 15px;
    font-weight: 400;
}

.testimonials .feedback .company-info {
    font-size: 12px;
}

.testimonials .owl-theme .owl-controls .owl-page span {
    border-radius: 20%;
    max-height: 2px;
}

.testimonials .customNavigation a {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.testimonial .container {
  width: 100%;
}

.center-col{ float:none !important; margin-left:auto !important; margin-right:auto !important}
.testimonial .container {
  width: 100%;
}

/*Testimonial Style-2*/

.testimonials-2 {
    -webkit-background-size: cover;
            background-size: cover;
}

.testimonials-2 .feedback {
    max-width: 850px;
}

.testimonials-2 .feedback .message {
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: #000;
    font-style: normal;
    margin-top: 20px;
    margin-bottom: 20px;
}

.testimonials-2 .feedback .name {
    color: #282828;
    margin-top: 15px;
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;
}

.testimonials-2 .feedback .image {
    font-size: 24px;
    border: 2px solid transparent;
    -webkit-box-shadow: 0px 0px 0px 2px #ffffff;
            box-shadow: 0px 0px 0px 2px #ffffff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: auto;
    margin-top: 5px;
    overflow: hidden;
}

.testimonials-2 .feedback .image img {
    width: 100%;
    vertical-align: top;
}
.testimonials-2 .feedback .company-info {
    text-transform: uppercase;
    color: #9ba0ae;
    font-weight: 400;
    font-size: 12px;
}

.testimonials-2 .owl-theme .owl-controls .owl-page span {
    border-radius: 20%;
    max-height: 2px;
}

.testimonials-2 .customNavigation a {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*Testimonial Style-3*/

.testimonials-3 .container {
  width: 100%;
}


.testimonials-3 {
    -webkit-background-size: cover;
            background-size: cover;
}


.testimonials-3.color-overlay {
    padding-top: 900px;
    padding-bottom: 90px;
}

.testimonials-3 .feedback {
    max-width: 750px;
    margin: 0 auto;
    padding:0 20px;
}

.testimonials-3 .feedback .image {
    font-size: 24px;
    border: 2px solid transparent;
    -webkit-box-shadow: 0px 0px 0px 2px #ffffff;
            box-shadow: 0px 0px 0px 2px #ffffff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: auto;
    margin-top: 5px;
    overflow: hidden;
}

.testimonials-3 .feedback .image img {
    width: 100%;
    vertical-align: top;
}

.testimonials-3 .feedback .message {

    font-style: normal;
    margin-top: 20px;
    margin-bottom: 20px;
}

.testimonials-3 .feedback .name {
    margin-top: 15px;
    font-weight: 400;
}

.testimonials-3 .feedback .company-info {
    font-size: 12px;
}

.testimonials-3 .owl-theme .owl-controls .owl-page span {
    border-radius: 20%;
    max-height: 2px;
}

.testimonials-3 .customNavigation a {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.testimonials-3 .container {
  width: 100%;
}

.consult-fdk-msg{
  padding-top: 20px;
/*  padding-right: 80px;*/
  padding-bottom: 20px;
  text-align: left;
}
.consult-fdk-img{
    border: 2px solid transparent;
    -webkit-box-shadow: 0px 0px 0px 2px #ffffff;
            box-shadow: 0px 0px 0px 2px #ffffff;
    width: 120px;
    height: 100px;
    line-height: 80px;
    margin-top: 5px;
    overflow: hidden;
    text-align: left;
}
.consult-fdk-cn{
  padding-top: 10px;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
  color: #f9b701;
  text-align: left;
}
.consult-fdk-cc{
  font-weight: 300;
  font-size: 15px;
  color: #2c2c2c;
  text-align: left;
}

/* ==================================================================
 CLIENT
================================================================== */

#clients-1{
  padding-top: 30px;
}

.clients .owl-theme .owl-controls .owl-page span {
    border-radius: 20%;
    max-height: 2px;
}


.clients {
    margin: 0;
    /*padding: 0;*/
    list-style: none;
    overflow: hidden;
}

.clients li {
    float: left;
    position: relative;
    padding: 20px 0;
    text-align: center;
}


.clients li a img {
    width: 100%;
}

.clients li a, .clients li img {
    display: block;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.clients.grid-2 li {
    width: 50%;
}

.clients.grid-3 li {
    width: 33.33%;
}

.clients.grid-4 li {
    width: 25%;
}

.clients.grid-5 li {
    width: 20%;
}

.clients.grid-6 li {
    width: 16.66%;
}




/* ==================================================================
  CALL TO ACTION
================================================================== */
.callto {
 /* margin-top: 30px;*/
  background: url('../images/bg-5.jpg') no-repeat center fixed;
  -webkit-background-size: cover;
          background-size: cover;
  color: #ffffff;
}
.callto .color-overlay {
    background: rgba(0, 0, 0, .4);
    padding-top: 90px;
    padding-bottom: 90px;
}
.callto .call-info {
  padding-bottom: 20px;
}

 .call-title{
   font-weight: 400;
  font-size: 36px;
  letter-spacing: 2px;
 }

 .call-number{
   font-weight: 200;
  font-size: 36px;
  letter-spacing: 2px;
 }

.callto h2 {
    line-height: 2rem;
}
.call-subtitle{
  text-transform: uppercase;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 1px;
}

/* ==================================================================
 Promo-Box
================================================================== */

.promo-box {
    padding: 40px;
    display: block;
    width: 100%;
}

.promo-box,
.text-center .promo-btn,
.text-center .promo-btn a,
.full-width.promo-box .container {
    position: relative;
}

.promo-box h1,
.promo-box h2,
.promo-box h3,
.promo-box h4,
.promo-box h5,
.promo-box h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
}


.text-center .promo-btn,
.text-center .promo-btn a,
.text-center .promo-info span,
.text-center .promo-info {
    display: inline-block;
}

.promo-info {
    padding-right: 200px;
}

.promo-info-t {
    color:#ffffff;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
        text-shadow: 2px 2px #000000;
  background: rgba(0, 0, 0, 0.5);
  display: inline-block;
  padding: 20px;
}

.text-center .promo-info {
    padding-right: 0px;
}


.promo-info h1 span,
.promo-info h2 span,
.promo-info h3 span,
.promo-info h4 span,
.promo-info h5 span,
.promo-info h6 span {
    margin-top: 0px;
    display:inline;
}

.promo-info span {
    margin-top: 10px;
    display: block;
    color: #282828;
}
.highlight-color{
  color: #fff ;
}
.promo-box.gray-bg {
    background: #f5f5f5;
}

.promo-box.offwhite-bg {
    background: #e6ebeb;
}

.promo-box.green-bg {
    background: #0d3948;
}

.promo-box.dark-bg {
    background: #222;
}

.light-txt, .light-txt p {
    color: #fff !important;
}   

.light-txt{
  font-weight: 400;
  font-size: 23px;
  text-transform: uppercase;
}

.light-txt-st{
  font-weight: 300;
  font-size: 21px;
  color: #ffffff;
  letter-spacing: 2px;

}

.round-5 {
    border-radius: 5px;
}

.promo-btn {
    position: absolute;
    right: 40px;
    top: 50%;
    margin-top: -28px;
}

.promo-btn a.btn {
    margin-right: 0px;
}

.text-center .promo-info span {
    margin-bottom: 20px;
    width: 100%;
}

.promo-info-2 h4 {
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
    margin-bottom: 20px;
    width: 100%;
}

.promo-info-2 span{
  color: #fff;
}

.promo-btn a.btn,
.promo-box a.btn {
    padding: 15px 30px;
}

.promo-box.border-box {
    border: 1px solid #ececec;
}

.full-width.promo-box {
/*    padding: 60px 0;*/
}


.full-width.promo-box .promo-btn {
    right: 0px;
}

/* ==================================================================
 TEAM
================================================================== */

.full-team {
  padding-top: 60px;
  padding-bottom: 40px;
}



.team-member {
    overflow: hidden;
}

.team-member ,
.team-member .team-img {
    position: relative;
    width: 100%;
    padding: 5px;
}

.team-member .team-img img{
    width: 100%;
    height: auto;
}

.team-member .s-link a{
    margin: 0 10px;
    color: #1d1d1d;
    font-size: 24px;
}


.team-hover {
    width: 100%;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.90);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}


.team-member:hover .team-hover,
.team-member:hover .team-hover .desk,
.team-member:hover .team-hover .s-link {
    opacity: 1;
}


.team-member:hover .team-hover .s-link {
    bottom: 10%;
}

.team-member:hover .team-hover .desk {
    top: 35%;
}

.team-hover .desk {
    position: absolute;
    padding: 10px;
    top: 0%;
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(-55%);
    -ms-transform: translateY(-55%);
    transform: translateY(-55%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
}


.team-hover .s-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    -webkit-transform: translateY(45%);
    -ms-transform: translateY(45%);
    transform: translateY(45%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    font-size: 35px;
}

.team-hover .s-link a {
    text-align: center;
    color: #6f8b9d;
}


.desk,
.desk h4,
.team-hover .s-link a {
    text-align: center;
    color: #222;
}


.t-name{
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.t-deg{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: capitalize;
}

.desk p {

    font-size: 18px;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 30px;
}

.team .owl-theme .owl-controls .owl-page span {
    border-radius: 20%;
    max-height: 2px;
}


.team-title {
    position: static;
    padding: 20px 0;
    display: inline-block;
    letter-spacing: 2px;
    width: 100%;
}

.team-title h5 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom:0px ;
    display: block;
    text-transform: uppercase;
    text-align: center;
}

.team-title span {
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
    color: #a5a5a5;
    letter-spacing: 1px;
    text-align: center;
}

.team-title p {
  text-align: left;
    padding-top: 10px;
    letter-spacing: normal;
    line-height: 30px;
}

/* ==================================================================
  PACKAGES
================================================================== */

.packages {
    padding-bottom: 60px;
    background: #FFFFFF;
    width: 100%;
}

.package-heading{
  margin-top: 40px;
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-transform: uppercase;
}

.single-package {
    width: 100%;
    background: #FFFFFF;
    overflow: auto;
    border: 1px solid #c9c9c9;
    border-radius: 0px;
    margin-bottom: 60px;
}

.single-package .price {
    background: #FFFFFF;
}

.single-package .price h2 {
    color: #000;
    font-size: 50px;
    font-weight: 200;
    padding: 0px;
    margin: 0px;
}

.single-package .price p{
  font-size: 14px;
  margin-bottom: 25px;
}

.single-package .price .sign {
    vertical-align: super;
    font-size: 16px;
}

.single-package .package-feature {
  margin-top: 15px;
  margin-bottom: 15px;
}

.single-package .package-feature > li {
  border-top: 1px solid rgba(125,125,125,0.2);
  margin-top: 7px;
  margin-bottom: 7px;
}


/* Button hover effect */

.hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #111;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-right:hover, .hvr-bounce-to-right:focus, .hvr-bounce-to-right:active {
  color: white;
}
.hvr-bounce-to-right:hover:before, .hvr-bounce-to-right:focus:before, .hvr-bounce-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* version 2 */
.hvr-bounce-to-right-white {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-right-white:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-right-white:hover, .hvr-bounce-to-right-white:focus, .hvr-bounce-to-right-white:active {
}
.hvr-bounce-to-right-white:hover:before, .hvr-bounce-to-right-white:focus:before, .hvr-bounce-to-right-white:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/*packages style-2*/

.price-col {
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 40px;
    text-align: center;
}

.price-col.feature {
    background: #222222;
    border: 1px solid #222222;
}

.price-col h1 {
    font-weight: 400;
    letter-spacing: 3px;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.price-col  .p-value {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 0;
    margin-bottom: 30px;
}

.price-col.feature .p-value {
    border-top: 1px solid rgba(255,255,255,.2);
    border-bottom: 1px solid rgba(255,255,255,.2);
    padding: 15px 0;
    margin-bottom: 30px;
}

.price-col  .dollar {
    font-size: 42px;
    font-weight: normal;
    font-weight: 400;
}

.price-col  .dollar span {
    font-size: 16px;
    margin-left: -10px;
}

.price-col  .duration {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.price-col ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
    display:block;

}

.price-col ul li {
    margin-bottom: 15px;
    color: #222222;
    text-transform: capitalize;
}

.price-col.feature ul li {
    color: #7d7d7d;
    text-transform: capitalize;
}

.price-col .p-btn {
    border: 1px solid #e8e8e8;
    padding: 5px 20px;
    display: inline-block;
    color: #222222;
    text-transform: uppercase;
    display: inherit;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 2px;
}

.price-col .p-btn:hover {
    border: 1px solid #222;
    background: #222;
}

.price-col.feature .p-btn, .price-col.feature .p-btn:hover {
    background: #282828;
    color: #e8e8e8;
}

.price-col.feature .p-btn:hover,
.price-col .p-btn:hover,
.price-col.feature {
    color: #fff;
}


/*packages style-3*/

.price-col-2 {
    background: #fff;
    border: 1px solid #e8e8e8;
    padding: 10px;
    text-align: center;
}

.price-col-2.feature {
    background: #222222;
    border: 1px solid #222222;
}

.price-col-2 h1 {
    font-weight: 400;
    letter-spacing: 3px;
    font-size: 17px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.price-col-2  .p-value {

    padding: 15px 0;
    margin-bottom: 30px;
}

.price-col-2.feature .p-value {

    padding: 15px 0;
    margin-bottom: 30px;
}

.price-col-2  .dollar {
    font-size: 42px;
    font-weight: normal;
    font-weight: 400;
}

.price-col-2  .dollar span {
    font-size: 16px;
    margin-left: -10px;
}

.price-col-2  .duration {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.price-col-2 ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
    display:block;

}

.price-col-2 ul li {
    margin-bottom: 15px;
    color: #222222;
    text-transform: capitalize;
}

.price-col-2.feature ul li {
    color: #7d7d7d;
    text-transform: capitalize;
}

.price-col-2 .p-btn {
    border: 1px solid #e8e8e8;
    padding: 5px 20px;
    display: inline-block;
    color: #222222;
    text-transform: uppercase;
    display: inherit;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 2px;
}

.price-col-2 .p-btn:hover {
    border: 1px solid #222;
    background: #222;
}

.price-col-2.feature .p-btn, .price-col.feature .p-btn:hover {
    background: #282828;
    color: #e8e8e8;
}

.price-col-2.feature .p-btn:hover,
.price-col-2 .p-btn:hover,
.price-col-2.feature {
    color: #fff;
}

/* ==================================================================
  FUN FACTORS
================================================================== */

.funfactor {
/*    background: url('../images/funfactor/bg-1.jpg') no-repeat center fixed;*/
    -webkit-background-size: cover;
            background-size: cover;
    color: #efefef;
}

.funfactor .color-overlay {
    background: rgba(0, 0, 0, .4);
    padding-top: 140px;
    padding-bottom: 40px;
}

.funfactor .fun-info {
  padding-bottom: 20px;
}

.funfactor .icon-container {
    display: block;
    margin-top: 5px;
}

.funfactor .icon-container i {
    font-size: 30px;
    color: #279aae;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}

.funfactor h2 {
    line-height: 2rem;
}

/*Funfactor Style-2*/

.funfactor-1 {
/*    background: url('../images/funfactor/bg-2.jpg') no-repeat center fixed;*/
    -webkit-background-size: cover;
            background-size: cover;
    color: #ffffff;
}

.funfactor-1  .color-overlay {
    background: rgba(0, 0, 0, .7);
    padding-top: 40px;
    padding-bottom: 40px;
}

.funfactor-1  .fun-info {
  padding-bottom: 20px;
}

.funfactor-1  .icon-container {
    display: block;
    margin-top: 5px;
}

.funfactor-1  .icon-container i {
    font-size: 40px;
    color: #b2b2b2;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}

.funfactor-1  h2 {
    line-height: 2rem;
}

.fun-work{
  font-weight: 500;
  text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
}

/*Funfactor Style-3*/

.funfactor-2 {
    background: #ffffff;
    -webkit-background-size: cover;
            background-size: cover;

}

.funfactor-2 .color-overlay {

    padding-top: 50px;
    padding-bottom: 50px;
}

.funfactor-2 .fun-info {
  padding-bottom: 20px;
}

.funfactor-2 .icon-container {
    display: block;
    margin-top: 5px;
}

.funfactor-2 .icon-container i {
    font-size: 35px;
    color: #abbbc6;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}

.funfactor-2 h2 {
    line-height: 2rem;
}

.fun-work-2{
  padding-top: 5px;
  font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}


/*Funfactor Style -4*/

 .res-funfactor {
    background: #ffffff;
    -webkit-background-size: cover;
            background-size: cover;


}



.res-funfactor .fun-info {
  color: #000;
  padding-bottom: 20px;
}

.res-funfactor .icon-container {
    display: block;
    margin-top: 5px;
}

.res-funfactor .icon-container i {
    font-size: 30px;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}

.res-funfactor h2 {
    line-height: 2rem;
}

.res-funfactor .fun-info .fun-work{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
}

.icon-container-2{
  font-size: 40px;
    color: #b2b2b2;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}


/* ==================================================================
  BLOG SECTIONS
================================================================== */


/*Blog Style-1*/

 .blog{

  text-align: left;
 }

.post-img{


}

/*Blog image hover effect*/

.figure {
    position: relative;
}
.figure i{
    position: absolute;
    bottom: -8px;
    right: 30px;
    color: #fff;
    font-size: 18px;
    z-index: 100;
}
.figure i:after {
    position: absolute;
    content: "";
    left: -12px;
    top: -10px;
    width: 40px;
    height: 40px;
    background-color: #68c3a3;
    z-index: -1;
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.post-thumbnail {
    margin-bottom: 20px;
    overflow: hidden;
}
.post-thumbnail img{
    width: 100%;
    opacity: 0.8;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
.post-thumbnail img:hover {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}


.blog-post-thumbnail {
    margin-bottom: 20px;
    overflow: hidden;
}
.blog-post-thumbnail img{
    width: 100%;
    opacity: 0.8;

}


 /*Blog info*/

 .blog-title{
   text-transform: uppercase;
   font-weight: 400;
   font-size: 16px;
   letter-spacing: 1px;
   padding-top: 5px;
   color: #000;

 }

 .blog-post{
  color: #949494;
 }

 .author{
  color: #282828;
  font-size: 14px;
  font-weight: 400;
  padding-right: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
 }

 .date{
  color: #262626;
   font-size: 12px;
   padding-bottom: 20px;
   text-transform: uppercase;
 }

 .read-more{
  color: #949494;
 }

 .read-more :hover {
  color: #279aae;
 }



/*BLOG STYLE- 3*/

.blog-card {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
}
.blog-card img {
  display: table;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}
.blog-card .card-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.7)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  opacity: 0;
}
.blog-card .card-content {
  height: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 0;
}
.blog-card .card-content:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: -1;
  -webkit-transition: top 0.3s cubic-bezier(0.36, 0.71, 0.44, 0.97);
  transition: top 0.3s cubic-bezier(0.36, 0.71, 0.44, 0.97);
}
.blog-card .card-content h3 {
  font-weight: 300;
  text-transform: capitalize;
  font-size: 22px;
  padding: 0 20px;
  margin-top: 0;
  position: absolute;
  top: 35%;
  width: 100%;
}
.blog-card .card-content h3 a {
  color: #000;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.blog-card .blog-date {
  display: block;
  position: relative;
  font-size: 1.125em;
  line-height: 1;
  font-weight: 800;
  margin-top: -15px;
}
.blog-card .blog-date span {
  clear: both;
  display: block;
  font-size: 0.6111111111111111em;
  text-transform: uppercase;
}
.blog-card .blog-date:before {
  content: '';
  position: absolute;
  height: 58px;
  width: 58px;
  background: #fff;
  border-radius: 16%;
  top: -14px;
  left: 50%;
  margin-left: -29px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
  -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}
.blog-card .category {
  position: absolute;
  display: block;
  top: 20%;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 13px;
  color: #bcc6cc;
}
.blog-card:hover .card-overlay {
  opacity: 1;
}
.blog-card:hover .card-content:before {
  top: 100%;
}
.blog-card:hover .card-content h3 a {
  color: #fff;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
@media (max-width: 580px) {
  .blog-col {
    width: 100%;
    margin-bottom: 40px;
  }
}



/* ==================================================================
  BLOG LIST PAGE
================================================================== */

/*Blog LIST Creative*/

.blog-cr-bg{
  background-color: #f8f8f8;
  padding-bottom: 50px;
}

.blog-cr-heading{
  font-weight: 400;
  font-size: 24px;
  margin-top: 80px;
  padding-right: 70px;
  text-transform: uppercase;
  text-align: right;
  color: #7fa3c7;
}

.blog-cr-heading.right{
  text-align: left;
  padding-left: 70px;
}

.cr-blog-under-line{
    position: relative;
    left: -200px;
    z-index: 1;
    width: 325px;
    height: 1px;
    background: #5877f6;
    margin-bottom: 10px;
}


.cr-blog-under-line.right{
  position: relative;
  left: 400px;
  width: 625px;
}

.blog-cr-author{
  font-weight: 400;
  font-size: 15px;
  color: #464646;
  text-align: right;
  text-transform: capitalize;
  padding-right: 70px;
  letter-spacing: 1px;
}


.blog-right-pic-1{
  margin-top: 80px;
  width: 80%;
  height: 385px;
  background: url(../images/blog/rp-1.jpg) no-repeat;
  background-size: cover;
}

.blog-right-pic-2{
  margin-top: 80px;
  width: 80%;
  height: 385px;
  background: url(../images/blog/rp-2.jpg) no-repeat;
  background-size: cover;
}

.blog-left-pic-1{
  margin-top: 80px;
  width: 100%;
  height: 385px;
  background: url(../images/blog/lp-1.jpg) no-repeat;
  background-size: cover;
}



.blog-list-title{
background-color: #f8f8f8;
padding-top: 100px;
padding-bottom: 70px;
width: 100%;
display: block;
}

.blog-item-date {
    width: 100px;
    position: absolute;
    top: 0px;
    left: -50px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    color: #1d1d1d;
}

.date-num{
  float: right;
  color: black;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  vertical-align: middle;
  color: #464646;
}
.inline-date{
  display: none;
}

.year{
  padding-left: 3px;
  padding-bottom: 10px;
}

.breadcrumb {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: transparent;
    border-radius: 4px;
    letter-spacing: 0.2em;
}

.breadcrumb li {
    text-transform: uppercase;
}

.breadcrumb li > a  {
    color: #000;
}

.breadcrumb li > a:hover {
    color: #222;
}

.breadcrumb>.active {
    color: #222;
}


.blog-page-heading{
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
}


.blog-image{
  width: 100%;
  height: auto;
}

.blog-header{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px;
}

.blog-image-parallax{
  width: 100%;
  height: 400px;
  position: relative;
}

.blog-media{
  margin-bottom: 30px;
}

.blog-figure a img{
  width: 100%;
}

.h_iframe{
  position:relative;
}

.h_iframe .ratio {
  display:block;
  width:100%;
  height:auto;
}

iframe{
  outline: none;
  border: none;
}

.h_iframe iframe {
  border: none;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.all-blog{
  width: 100%;
}

.blog-post-title{
   font-weight: 400;
   font-size: 24px;
   text-transform: uppercase;
   color: rgba(0,0,0,.8);
   letter-spacing: 1px;
   text-align: left;
 }

.blog-post-title :hover {
    color: #279aae;
}

.blog-list-item-detail {
  text-align: left;
  position: relative;
  top: -25px;
  text-transform: capitalize;
  vertical-align: super;
  font-weight: 300;
  font-size: 16px;
}
.blog-list-item-detail a {
  color: #949494;
  padding-right: 5px;
}
.blog-list-item-detail a:hover{
  color: #279aae;
  padding-right: 5px;
}
.blog-list-item-detail i {
  padding-right: 5px;
}

.blog-list-item-body {
  text-align: left;
}

.blog-list{
  padding-top: 50px;
  margin: 0 auto;
}

#content-slider .item img{
    display: block;
    width: 100%;
    height: auto;
}

.all-blog .owl-carousel .owl-controls .owl-page span {
  margin-top: -70px;
}

.all-blog .pull-top{
  position: relative;
  top: -30px;
}


/*
 * Pagination
 */

.pagination{
  font-size: 11px;
    padding-top: 40px;
}
.pagination a{
  display: inline-block;
  min-width: 20px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  padding: 4px 8px;


  color: #5b5b5b;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;

  -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}
.pagination .fa{
  margin-top: 1px;
  font-size: 16px;
}

.blog-grid-left{
  text-align: right;
}
.blog-grid-right{
  text-align: left;
}
.blog-grid-right.blog-cr-author{
  padding-left: 70px;
  padding-right: 0px;
}

/* ==================================================================
 SINGLE BLOG PAGE
================================================================== */
/* Blog Item */

.single-post{
  padding-top: 50px;
  padding-left: 20px;
}

.sidebarfixed {
  position: fixed!important;
  top: 0;
}
.blog-item {
  position: relative;
  margin-bottom: 60px;
}


.blog-item-detail {
  position: relative;
  padding: 10px 0;
  margin: 0 10px;
  text-transform: capitalize;
  font-weight: 300;
  font-size: 13px;
}
.blog-item-detail a {
  color: #949494;
  padding-right: 7px;
}
.blog-item-detail a:hover{
  padding-right: 7px;
}
.blog-item-detail i {
  padding-right: 5px;
}


.blog-item-title-wrapper {
  padding-top: 40px;
  margin: 0 10px;
}
.blog-item-title h1 {
  margin-top: 0;
  font-weight: 300;
}
.blog-item-title span {
  font-weight: 700;
}


.blog-page-post-title {
   text-transform: uppercase;
   color: rgba(0,0,0,.8);
   font-weight: 400;
   font-size: 23px;
   letter-spacing: 2px;
  text-align: left;
  margin: 30px 0 0 0;
}


.post-date {
  margin: 15px 0;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: 300 !important;
}
.blog-item-body {
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.8;
}
.blog-item-body blockquote {
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}


.blog-post {
  margin-bottom: 80px;
}
.blog-post .blog-item-body {
  margin: 30px 0;
}
.blog-post .blog-item-detail {
  font-size: 16px;
  padding: 15px 0;
  text-align: left;
}
.article-nav {
  font-weight: 300;
  text-transform: capitalize;
  text-align: center;
  margin: 30px 0;
  border-top: 1px solid #ebebf5;
  border-bottom: 1px solid #ebebf5;
}
.article-nav-link {
  display: inline-block;
  padding: 30px 0;


}
.article-nav-link p {
  display: inline-block;
  margin: 0;
  font-size: 18px;

}
.article-nav-link span {
  font-weight: 500;
}
.article-nav-link i {
  font-size: 32px;
}

.sidebar{
  padding: 0px 30px;
}

.sidebar-left{
  margin: 0 auto;
  padding-right: 20px;
}

.widget {
  width: 100%;
  margin-bottom: 60px;
  padding: 5px;
}
.widget-title {
  margin-bottom: 15px;
  margin-top: 0;
  padding: 5px;
  border: 1px solid #858585;
  color: rgba(0,0,0,.9);
  background-color: #FFFFFF;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.widget-body {

  font-size: 15px;
  padding: 10px;
}
.widget-body .post-date {
  margin: 0;
}
.widget-body a {
  text-transform: uppercase;
  color: rgba(0,0,0,.8);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
}
.widget-body a:hover{
}
.widget-text {
  line-height: 1.7;
}

.search-wrap {
  position: relative;
}

.input-field {
  color: #949494;
  outline: none;
  margin-bottom: 15px;
  margin-top: 0;
  padding: 8px 10px;
  border: 1px solid #858585;

  font-size: 16px;
  font-weight: 300;
  letter-spacing: .05em;
  line-height: 1.5;
  width: 100%;
  background: transparent;
  -webkit-transition: border-color 0.25s;
          transition: border-color 0.25s;
  -webkit-appearance: none;
}

.input-field:focus {
  color: #949494;
}

.search-button {
  background: transparent;
  position: absolute;
  top: 40%;
  right: 1px;
  overflow: hidden;
  width: 42px;
  height: 35px;
  line-height: 38px;
  margin-top: -20px;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
}


/* Recent Posts */
.widget-posts-recent ul li {
  position: relative;
  margin: 0;
  padding: 8px 0;
  line-height: 1.5em;
}
.widget-posts-recent ul li span {
  font-size: 11px;
  font-weight: 400;
  color: #000;
  display: block;
  padding-top: 3px;

}
.widget-posts-recent ul i {
  position: absolute;
  top: 9px;
  left: -4px;
  font-size: 20px;

}
.widget-posts-img {
  float: left;
  margin: 0 7px 0 0;
  width: 70px;
  height: auto;
}
.widget-posts-descr {
  overflow: hidden;
  text-transform: capitalize;
}
.widget-categories {
  font-size: 13px;
}
.widget-categories li {
  line-height: 1.5em;
  padding: 4px 0;
  position: relative;
}
.widget-categories li a {
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}
.widget-categories li small {
  font-size: 10px;
  color: #757a86;
}
.widget-categories li i {
  position: absolute;
  top: 9px;
  left: -4px;
  font-size: 20px;
  opacity: 0.5;
}

.tags {
  text-align: left;
  padding: 5px;
}
.tags a {
  font-weight: 500;
  width: auto;
  color: #ffffff;
  display: inline-block;
  margin: 3px 0px;
  padding: 0px 5px;
  border: 1px solid #949494;
  background: #4b4b4b;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.tags a:hover {
}

.default-tabs > li > a {
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: .05em;
}
.default-tabs-cont {
  padding: 30px 0 0;
  line-height: 1.8;
}



/* Comments */

.media-list{
  padding-top: 30px;
}

.widget-comments li {
  padding: 8px 0;
  border-bottom: 1px solid #f5f5fa;
}
/* Comments */
.blog-comment-section {
  margin: 40px 0;
}
.blog-comment-section .form-message {

}
li.comment-item {
  padding: 30px 0;
  border-bottom: 1px solid #ebebf5;
  margin-bottom: 5px;
}
.comment-item {
  padding: 30px 0 0 0;
  font-size: 14px;
  overflow: hidden;
}
.comment-item:first-child {
  padding-top: 0;
}
.comment-item p {
  margin-bottom: 10px;
}
.comment-item a {
  font-weight: 500;
  color: #555555;
  opacity: 1;
  -webkit-transition: opacity 0.27s ease-out;
          transition: opacity 0.27s ease-out;
}
.comment-item a:hover {
  opacity: .8;
}
.comment-body{
  text-align: left;
}
.comment-item i {
  padding-top: 9px;
}
.comment-item-data {
  font-weight: 300;
  letter-spacing: 1px;
  float: left;
  text-transform: uppercase;
  font-size: 12px;
}
.comment-author {
  display: inline-block;
  margin-right: 10px;
  font-weight: 500;
}

.comment-author a{
  color: #000;
  display: inline-block;
  margin-right: 10px;
  font-weight: 500;
}

.comment-avatar {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  font-size: 50px;
  line-height: 50px;
  border-radius: 50%;
  overflow: hidden;

}

.comment-child .comment-avatar{
  margin-left: 20px;
}

.comment-body{
  float: left;
}

.comment-child{
  padding-top: 20px;
  float: left;
}

.comment-reply {
  float: left;
  font-size: 13px;
}
/* Pagination */
.pagination {
  font-size: 13px;
}
.pagination a {
  display: inline-block;
  min-width: 30px;
  margin: 2px;
  padding: 3px 5px;
  border: 1px solid #b8b7cb;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
}
.pagination a.active,
.pagination a.active:hover {
  cursor: default;
}
.pagination a i {
  vertical-align: middle;
}
.pagination .fa,
.pagination .icon {
  line-height: 21px;
  font-size: 18px;
}

/*---------------------------------------
   SECTION:  reply section of blog
-----------------------------------------*/

.form-control{
  border-radius: 0px;
}

.comment-reply .contact-box {
    padding-bottom: 20px;
}

.comment-reply .contact-box .contact-button {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: auto;
    position: relative;
    font-size: 30px;
    line-height: 60px;
    vertical-align: middle;
    margin-top: -40px;
    background: #232323;
    -webkit-box-shadow: 0px 0px 0px 3px #232323;
            box-shadow: 0px 0px 0px 3px #232323;
    -webkit-backface-visibility: hidden;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.comment-reply  .contact-box .contact-button:hover {
    color: #ffffff;
}

.comment-reply  .contact-box .contact-form {
    margin-top: 20px;
    margin-left: -20px;
    margin-bottom: 80px;
}

.comment-reply  .contact-box .contact-form .success,
.comment-reply .contact-box .contact-form .error {
    display: none;
}


.comment-reply .contact-box .contact-form .input-box {
    margin-bottom: 10px;
    margin-top: 10px;
    height: 40px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: #FFFFFF;
    border: 1px solid #bbbbbb;
}

.comment-reply .contact-box .contact-form .textarea-box {
    margin-top: 20px;
    margin-bottom: 20px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: #FFFFFF;
    border: 1px solid #bbbbbb;
}

.comment-reply .contact-box .contact-form .textarea-box:active,
.comment-reply.contact-box .contact-form .textarea-box:focus,     {
    color: #FFF;
}



/* blog section css ends  */



/* ==================================================================
 CONTACT
================================================================== */

.contact-arch{
  background: url(../images/construct/con-bg-2.jpg) no-repeat center fixed;
    -webkit-background-size: cover;
            background-size: cover;
}
.contact-arch-overlay{
  background-color: rgba(27,27,27,.8);
}



.contact  .contact-box {
    /*padding-bottom: 20px;*/
}

.contact  .contact-address{
    /*text-align: left;*/
    margin-top: 10px;
    margin-bottom: 30px;
}

.contact  .contact-address i{
  font-size: 22px;
  vertical-align: middle;
  margin-right: 6px;
}

.contact  .contact-address ul li{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height:18px;
  padding: 10px;
  letter-spacing: 2px;
}
.contact  .contact-address ul li span{
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
  letter-spacing: 1px;
}

.contact  .contact-box .contact-button {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: auto;
    position: relative;
    font-size: 30px;
    line-height: 60px;
    vertical-align: middle;
    margin-top: -40px;
    background: #232323;
    -webkit-box-shadow: 0px 0px 0px 3px #232323;
            box-shadow: 0px 0px 0px 3px #232323;
    -webkit-backface-visibility: hidden;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.contact  .contact-box .contact-button:hover {
    color: #ffffff;
}

.contact  .contact-box .contact-form {
    margin-top: 10px;

}

.contact  .contact-box .contact-form .success,
.contact  .contact-box .contact-form .error {
    display: none;
}


.contact  .contact-box .contact-form .input-box {
    margin-bottom: 10px;
    margin-top: 10px;
    height: 50px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: #FFFFFF;
    border: 1px solid #949494;
    border-radius: 3px;
}

.contact  .contact-box .contact-form .textarea-box {
    margin-top: 20px;
    margin-bottom: 20px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: #FFFFFF;
    height: 140px;
    border-radius: 3px;
    border: 1px solid #949494;
}

/*CONTACT FORM-2*/

.contact-2  .contact-box {
    /*padding-bottom: 20px;*/
}

.contact-2 .contact-address{
    /*text-align: left;*/
    margin-top: 10px;
    margin-bottom: 30px;
}

.contact-2 .contact-address i{
  font-size: 22px;
  vertical-align: middle;
  margin-right: 6px;
}

.contact-2 .contact-address ul li{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  line-height:18px;
  padding: 10px;
  letter-spacing: 2px;
}
.contact-2 .contact-address ul li span{
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
  letter-spacing: 1px;
}

.contact-2 .contact-box .contact-button {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: auto;
    position: relative;
    font-size: 30px;
    line-height: 60px;
    vertical-align: middle;
    margin-top: -40px;
    background: #232323;
    -webkit-box-shadow: 0px 0px 0px 3px #232323;
            box-shadow: 0px 0px 0px 3px #232323;
    -webkit-backface-visibility: hidden;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.contact-2 .contact-box .contact-button:hover {
    color: #ffffff;
}

.contact-2 .contact-box .contact-form {
    margin-top: 10px;

}

.contact-2 .contact-box .contact-form .success,
.contact  .contact-box .contact-form .error {
    display: none;
}


.contact-2 .contact-box .contact-form .input-box {
    margin-bottom: 10px;
    margin-top: 10px;
    height: 40px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: transparent;
    border: 1px solid #ffffff;
}

.contact-2 .contact-box .contact-form .textarea-box {
    margin-top: 20px;
    margin-bottom: 20px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: transparent;
    height: 120px;
    border: 1px solid #949494;
}

/* ==================================================================
GOOGLE MAP
================================================================== */

#map {
    width: 100%;
    height: 400px;
}

#map2 {
    width: 100%;
    height: 500px;
}


/* ==================================================================
 FOOTER
================================================================== */


.footer-section {
  background: #1d1d1d;
  padding-top: 30px;
  overflow-x: hidden;
}

.footer-logo p {
    display: inline-block;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 4px;
    padding-left: 4px;
    padding-top: 18px;
    text-transform: uppercase;
}
.list-inline {
    text-align: center;
    margin: 0 auto;
    padding: 30px 0;
}

.list-inline>li {
    text-align: center;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    margin: 15px;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    text-transform: capitalize;
}

.list-inline a{
   color: #949494;
}

.input-group{
  margin: 30px auto;
  width: 90%;
  color: #949494;
}

.subscriber{
  border: 2px solid #fff;
}

.subscriber:focus{
  color: #949494;
}

#widget-subscribe-submit-button{
  position: relative;
  margin: 0px auto;
}

.modal-backdrop.in {
  opacity: .75;
}
.modal-content {
  box-shadow: none;
  border-radius: 0;
}
.modal-header {
  position: relative;
}
.modal-header .close {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  height: 20px;
  font-size: 15px;
}

.form-control{
  height: 100%;
}

.footer-logo{
  padding-left: 60px;
}

.footer-logo img{
/*  width: 40px;
  height: 40px;*/
}

.footer-content{
  padding: 10px 0 25px;
  text-align: left;
  color: #949494;
}

.footer-content .footer-column{
  padding: 0px auto;
}

.footer-title{
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: #ccc;
}

.list-tweets li{
  margin: 0 auto;
  padding-bottom: 10px;
  text-align: left;
  padding-left: 20px;
}

.list-tweets li:before {
    margin-left: -25px;
    position: absolute;
    font-family: fontawesome;
    content: "\f099";
}

.list-tweets .list-tweets-date {
    display: block;
    font-size: 12px;
    font-style: italic;
    opacity: 0.8;
}

.list-tweets li a{
  color: #ddd;
}

.social-icons {
    padding: 30px;
    margin-left: 40px;
    text-align: left;
}

.social-icons li {
    display: inline-block;
    margin: 5px;
}

.social-icons li a {
  margin: 0 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 37px;
  font-size: 14px;
  color: #949494;
}

.copyright {
    font-family: "Lato",sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #808080;
    padding-top: 0px;
    margin-bottom: 5px;
    letter-spacing: 1px;
}
.copyright p{
  line-height: 1em;
}
.copyright .creo{
 color: #808080;
 font-size: 14px;
 letter-spacing: .4em;

}

/* footer 1 */
.footer__nav-link{
  color: #949494;
}
.footer-1 .footer__nav-item--heading {
    margin-bottom: 25px;
    font-weight: 400;
    color: #949494;
    /* text-transform: uppercase; */
}


.footer-1 .footer-desc{
    padding: 10px 60px 20px 60px;
    text-align: left;
    font-size: 14px;
}

.footer-2{
  background: #fafafa;
}
.footer-2-dark{
  background: #1d1d1d;
}

.footer-2 .footer-column p{
  text-transform: uppercase;
  font-size: 14px;
  color: #1d1d1d;
  font-weight: 700;
  padding-left: 120px;
}

.footer-2-dark .footer-column p{
  color: #fafafa;
}

.footer-2-light{
  background: #fafafa;
}

.footer-2-light .footer-column p{
  color: #1d1d1d;
}
.footer-2-light .footer-desc{
  color: #1d1d1d;
}
.footer-2 .col-md-6{
  text-align: right;
  padding-right: 80px;
}

.footer-2 .copyright-2{
  font-size: 14px;
  line-height: 1.25rem;
}

.footer-2 .social-icons{
  text-align: right;
  padding: 0px;
}
.footer-2 .social-icons li a{
  margin: 0px 2px;
}

.footer-2 .footer__nav-link{
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.footer-1 .copyright-3{
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 1em;
}

.email-3{
  padding-top: 20px;
}

.social-li a{
  padding-right: 10px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
}

.social-2{
  padding-left: 10px;
}


/* ==================================================================
 SHOP PAGE
================================================================== */

.shop-page{
  padding-top:80px;
}

.shop-page-title{
background-color: #f6f6f6;
padding-top: 90px;
padding-bottom: 60px;
width: 100%;
display: block;
}

.shop-page-heading{
  text-transform: uppercase;
  text-align: center;
  font-size: 38px;
  font-weight: 400;
  letter-spacing: 1.5px;
}

.body-content {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.page-content {
    padding: 40px 0;
    display: inline-block;
    width: 100%;
    text-align: left;
}
.page-content-2 {
    padding: 40px 0;
    display: inline-block;
    width: 100%;
}
.m-bot-50 {

   padding-top: 50px;
    margin-bottom: 90px;
}
.m-top-10 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}

.product-grid input {
    box-shadow: none;
    border-radius: 0;
    height: 40px;
}

.product-grid input:focus,
.product-grid .form-control:focus,
.portfolio-meta .form-control:focus{
    box-shadow: none;
    border-color: #000;
}

.product-grid .form-control,
.p-quantity .form-control,
.portfolio-meta .form-control {
    box-shadow: none;
    text-align: center;
}

.p-values{
  text-align: left;
}

.product-list {
    margin-bottom: 50px;
    min-height: 440px;
}

.product-list .product-img {
    position: relative;
    width: 100%;
    /*height: 340px;*/
    overflow: hidden;
    margin-bottom: 20px;
    border: 1px solid #adadad;
}

.product-img:hover>a:nth-of-type(2) {
    opacity: 1;
    z-index: 2;
}

.product-img>a:nth-of-type(2) {
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

.sale-label {
    position: absolute;
    left: 10px;
    top:10px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    background-color:rgba(249,183,1,.9);
    color: #000;
    text-transform: uppercase;
    z-index: 10;
}

.product-img>a, .product-img:hover>a {
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}


.product-list .product-img img,
.product-img img, .product-img>a {
    display: block;
    width: 100%;
    height: 100%;
}

.product-title h5 {
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 12px;
    text-transform: uppercase;
}

.product-title h5 a {
    color: #222;
}

.product-price {
   font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 1px;
}



.product-rating {
  font-size: 18px;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    color: #d6b161;
}


.product-grid .col-lg-1,
.product-grid .col-lg-10,
.product-grid .col-lg-11,
.product-grid .col-lg-12,
.product-grid .col-lg-2,
.product-grid .col-lg-3,
.product-grid .col-lg-4,
.product-grid .col-lg-5,
.product-grid .col-lg-6,
.product-grid .col-lg-7,
.product-grid .col-lg-8,
.product-grid .col-lg-9,
.product-grid .col-md-1,
.product-grid .col-md-10,
.product-grid .col-md-11,
.product-grid .col-md-12,
.product-grid .col-md-2,
.product-grid .col-md-3,
.product-grid .col-md-4,
.product-grid .col-md-5,
.product-grid .col-md-6,
.product-grid .col-md-7,
.product-grid .col-md-8,
.product-grid .col-md-9,
.product-grid .col-sm-1,
.product-grid .col-sm-10,
.product-grid .col-sm-11,
.product-grid .col-sm-12,
.product-grid .col-sm-2,
.product-grid .col-sm-3,
.product-grid .col-sm-4,
.product-grid .col-sm-5,
.product-grid .col-sm-6,
.product-grid .col-sm-7,
.product-grid .col-sm-8,
.product-grid .col-sm-9,
.product-grid .col-xs-1,
.product-grid .col-xs-10,
.product-grid .col-xs-11,
.product-grid .col-xs-12,
.product-grid .col-xs-2,
.product-grid .col-xs-3,
.product-grid .col-xs-4,
.product-grid .col-xs-5,
.product-grid .col-xs-6,
.product-grid .col-xs-7,
.product-grid .col-xs-8,
.product-grid .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


.product-grid .row {
    margin-right: -10px;
    margin-left: -10px;
}


/*SHOP SINGLE*/


#demo0{
  /*margin-left: 58px;*/
  padding-left: 15px;
  color: #000;
  width: 130px;
}

.p-quantity label{
    font-weight: 400;
    display: inline-block;
    width: 130px;
    letter-spacing: 2px;
    color: #000;
    font-size: 13px;
    text-transform: uppercase;
}

.product-details{
  text-align: left;
  padding-top: 90px;
}


.single-product-img {
    overflow: hidden;
}
.single-product-img{
    width: 100%;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.single-product-title h2{
  font-weight: 400;
    margin-bottom: 10px;
    font-size: 32px;
    text-transform: uppercase;
    text-align: left;

}



.review-comment-section {
  margin: 40px 0;
}

.normal-tabs{
  padding-top: 50px;
}
.normal-tabs .panel-body{
    padding: 30px 0;
}

.normal-tabs ul.nav-tabs {
    padding-left: 20px;
}
.tab-pane {
    line-height: 30px;
}

 .product-description{
  font-size: 16px;
  text-align: left;
 }


 /*IRIS -Shop Cart*/

.cart-img img {
    width: 60px;
    height: auto;
}

.cart-table   {
    border: 1px solid #ddd;
}

.cart-table  td a {
     text-transform: uppercase;
     font-weight: 400;
     font-size: 14px;
    color: #000;
}

.cart-table  td a:hover {
    color: #000;
}

.cart-table thead th {
    text-transform: uppercase;
}


.cart-table.table>tbody>tr>td,
.cart-table.table>tbody>tr>th,
.cart-table.table>tfoot>tr>td,
.cart-table.table>tfoot>tr>th,
.cart-table.table>thead>tr>td,
.cart-table.table>thead>tr>th {
    vertical-align: middle;
}

.cart-action input {
    padding: 6px 3px 6px 6px;
}

.cart-action input,
.cart-action button {
    float: left;
    margin-right: 3px;
    color: #000;
}

.cart-action button:hover i {
    color: #222;
}

.cart-quantity {
    width: 45px;
    border-radius: 0;
}

.cart-action .btn{
  margin: 0px 0px;
}


.accordion > dt{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.accordion > dt > a{
    display: block;
    position: relative;
    color: #222222;
    text-decoration: none;
    padding: 14px 20px;
    border: 1px solid #e8e8e8;
    letter-spacing: 3px;
}

.accordion > dt > a:hover{
    text-decoration: none;
    border-color: #ddd;
    background: #fbfbfb;
}

.accordion > dt > a.active{
    color: #000;
    border-color: #ddd;
    background: #f8f8f8;
    cursor: default;
}

.accordion > dt > a:after {
    content: "\f107";
    width: 15px;
    height: 15px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    right: 10px;
    font-family: 'FontAwesome';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #999;
    -webkit-font-smoothing: antialiased;
}

.accordion > dt > a.active:after,
.accordion > dt > a.active:hover:after{
    content: "\f106";
    color: #111;
}

.accordion > dt > a:hover:after{
    color: #444;
}

.accordion > dd{
    margin-bottom: 10px;
    padding: 10px 20px 20px;
    font-size: 14px;
    line-height: 1.8;
    color: #777;
}


.accordion > dt > a,
.accordion > dt > a:after,
.toggle > dt > a,
.toggle > dt > a:after {
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}



.portfolio-meta li {
    margin: 15px 0;
    color: #666;
}

.portfolio-meta li span {
  font-weight: 400;
    display: inline-block;
    width: 130px;
    letter-spacing: 2px;
    color: #000;
    font-size: 13px;
    text-transform: uppercase;
}

.portfolio-meta li a {
    margin-right: 10px;
    color: #cdcdcd;
}
.portfolio-meta li a:hover {
    color: #323232;
}

.cart-btn-row{
  padding-right: 50px;
  padding-top: 200px;
}

.cart-btn-row a {
    position: relative;
    right: -210px;
    margin-right: 5px;
}


.coupon-form input {
     margin-right: 10px;
    height: 40px;
    border-radius: 0;
}

.coupon-form label {
    margin-right: 20px;
    font-weight: normal;
    text-transform: uppercase;
}




.btn {
    /*margin-right: 20px;*/
    display: inline-block;
    border-radius: 0;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.btn.btn-default.focus, .btn.btn-default:focus, .btn.btn-default:hover{
    color: #222;
}

.btn-small {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: bold;
}

.btn-medium {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
}

.btn-dark-solid {
    background: #e21d39;
    color: #fff;
}

.btn-dark-solid:hover,
.btn-dark-solid:focus {
    color: #fff;
}


.input-box:active,
.textarea-box:active,
.input-box:focus,
.textarea-box:focus {
    border: 1px solid  #004dff;
    color: #111;
}




/* ==================================================================
 SCROLL TO TOP
================================================================== */

.scroll-up {
  position: fixed;
  display: none;
  z-index: 999;
  bottom: 0;
  right: 1em;
}

.scroll-up a {
  display: block;
  width: 50px;
  height: 30px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.scroll-up a:hover,
.scroll-up a:active {
  background-color: rgba(235, 235, 235, .8);
  color: #222;
}

/*
    Responsive styles
    --------------
    Contents
    ========
    min-width: 481px
    max-width: 767px
    min-width: 768px
    min-width: 992px
    min-width: 1200px
    max-width: 480px
*/

@media (min-width: 481px) {
  .hs1-h1{
    font-size: 60px;
  }

  .hs2-h1{
    font-size: 60px;
  }

  .hs3-h1{
    font-size: 60px;
  }
  .hs4-h1{
    font-size: 60px;
  }

  .hs5-h1{
    font-size: 60px;
  }

  .hs6-h1{
    font-size: 60px;
  }

  .hs7-h1{
    font-size: 60px;
  }

  .hs8-h1{
    font-size: 60px;
  }
  .hs9-h1{
    font-size: 60px;
  }
  .hs10-h1{
    font-size: 60px;
  }
  .hs11-h1{
    font-size: 60px;
  }
  h2{
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .hs1-h1{
    font-size: 60px;
  }
   .hs2-h1{
    font-size: 60px;
  }

  .hs3-h1{
    font-size: 60px;
  }
  .hs4-h1{
    font-size: 60px;
  }

  .hs5-h1{
    font-size: 60px;
  }
  .hs6-h1{
    font-size: 60px;
  }
  .hs7-h1{
    font-size: 60px;
  }
  .hs8-h1{
    font-size: 60px;
  }
  .hs9-h1{
    font-size: 60px;
  }
  .hs10-h1{
    font-size: 60px;
  }
  .hs11-h1{
    font-size: 60px;
  }

  h2{
    font-size: 28px;
    line-height: 1em;
    padding-bottom: 20px;
  }
  .content-slider-subtitle{
    font-size: 14px;
  }
  .carousel-indicators li{
    margin-bottom: 20%;
  }
  .carousel-indicators .active{
    margin-bottom: 20%;
  }
  .text-black-4{
    padding-bottom: 0px;
  }
  .text-blue{
    margin-left: 0px;
  }
  .text-blue-sub {
    margin-left: 0px;
  }
  .cd-headline{
    padding-top: 40%;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .hs1-h1{
    font-size: 90px;
  }
   .hs2-h1{
    font-size: 70px;
  }

  .hs3-h1{
    font-size: 60px;
  }
  .hs4-h1{
    font-size: 90px;
  }
  .hs5-h1{
    font-size: 90px;
  }

  .hs6-h1{
    font-size: 90px;
  }
  .hs7-h1{
    font-size: 90px;
  }
  .hs8-h1{
    font-size: 90px;
  }
  .hs9-h1{
    font-size: 70px;
  }
  .hs10-h1{
    font-size: 90px;
  }
  .hs11-h1{
    font-size: 70px;
  }

  h2{
    font-size: 36px;
  }
  .text-blue{
    margin-top: 300px;
  }

}

@media (min-width: 1200px) {
  .hs1-h1{
    font-size: 100px;
  }
   .hs2-h1{
    font-size: 70px;
  }

  .hs3-h1{
    font-size: 60px;
  }
  .hs4-h1{
    font-size: 90px;
  }
  .hs5-h1{
    font-size: 100px;
  }

  .hs6-h1{
    font-size: 70px;
  }

  .hs7-h1{
    font-size: 90px;
  }

  .hs8-h1{
    font-size: 80px;
  }
  .hs9-h1{
    font-size: 70px;
  }
  .hs10-h1{
    font-size: 90px;
  }
  .hs11-h1{
    font-size: 70px;
  }

   h2{
    font-size: 42px;
  }

  .text-blue{
    margin-top: 350px;
  }
}

@media (max-width: 480px) {
  .hs1-h1{
    font-size: 30px;
  }
  .hs2-h1{
    font-size: 30px;
  }

  .hs3-h1{
    font-size: 30px;
  }

  .hs4-h1{
    font-size: 30px;
  }
  .hs5-h1{
    font-size: 30px;
  }

  .hs6-h1{
    font-size: 30px;
  }
  .hs7-h1{
    font-size: 30px;
  }
  .hs8-h1{
    font-size: 30px;
  }
  .hs9-h1{
    font-size: 30px;
  }
  .hs10-h1{
    font-size: 30px;
  }
  .hs11-h1{
    font-size: 30px;
  }
  .inline-date{
    text-align: left;
    display: inline;
    vertical-align: middle;
  }
  .date-num{
    display: none;
  }
  .content-slider-subtitle{
    font-size: 14px;
  }
  .carousel-indicators li{
    margin-bottom: 30%;
  }
  .carousel-indicators .active{
    margin-bottom: 30%;
  }
  .text-black-4{
    padding-bottom: 0px;
  }
  .text-blue{
    margin-left: 0px;
  }
  .text-blue-sub {
    margin-left: 0px;
  }
  .cd-headline{
    padding-top: 50%;
  }
}

@media (min-width: 1200px) {
  .blog-list-wrap{
    margin: 0px 80px;
  }
}


/*Over Ride*/

.pad-bot-0{
  padding-bottom: 0px;
}

 .pad-top-60{
  padding-top: 60px;
 }

 .pad-top-80{
  padding-top: 80px;
 }

 .pad-bot-60{
  padding-bottom:60px; 
 }









 /*responsive fixes concat*/
 /* --------------------------------------
=========================================
RESPONSIVE FIXES
=========================================
-----------------------------------------*/

@media (max-width: 1024px) {

    .animated {
        -webkit-transform: translateZ(0);
    }

    
      header {
        background-attachment: scroll;
    }
}

 @media (max-width: 991px) {
 
    
    body {
        font-size: 15px;
        line-height: 26px;
    }

    header .color-overlay {
        min-height: 800px;
    }

    .app-brief .left-align {
        text-align: center;
    }

    .colored-line-left {
        float: none;
        margin: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
 
    
    html {
        font-size: 90%;
    }

    header .color-overlay {
        min-height: 780px;
    }
    
    .sticky-navigation .navbar-header {
        width: 100%;
    position: relative;
    text-align: center;
    margin-top: 10px;
    }
    .sticky-navigation .navbar-header a {
        float: none !important;
    }
    
    .sticky-navigation .navbar-header img {
        max-height: 40px;
    }
    
    .sticky-navigation .main-navigation {
    text-align: center;
    width: 100%;
    padding-top: 0;
  }

    header .intro-section {
        padding-top: 10%;
    }

    .features .features-left,
    .features .features-right {
        margin-top: 0;
    }

    .features .features-left .icon-container,
    .features .features-right .icon-container {
        display: none;
    }
}

@media (max-width: 767px) {
 
    
    html {
        font-size: 80%;
    }

    body {
        font-size: 15px;
        line-height: 26px;
    }

    header .color-overlay {
        min-height: 580px;
    }

    header .only-logo .navbar {
        padding: 20px;
    }
    
    .navbar-inverse .navbar-nav {
    padding-left: 10px;
    line-height: normal;
    text-align: center;
  }

  .navbar-inverse .navbar-nav>li {
    display: inline-block;
    margin-bottom: 0;
  }

    header .intro-section {
        padding-top: 9%;
    }

    .features .features-left,
    .features .features-right {
        text-align: center;
        margin-top: 5px;
    }

    .features .feature {
        margin-bottom: 70px;
    }

    .features .features-left .icon-container,
    .features .features-right .icon-container {
        float: none;
        margin-left: inherit;
        min-height: 70px;
    }

    .features .feature .icon-container .icon {
        margin: auto;
    }

    .download .subscription-form .input-box {
        margin: auto;
        width: 80%;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
 
    
    html {
        font-size: 65%;
    }

    header .color-overlay {
        min-height: 480px;
    }

    header .only-logo .navbar {
        padding: 10px;
    }

    header .intro-section {
        padding-top: 2%;
    }

    header .intro-section .intro {
        margin-bottom: 16px;
    }

    header .intro-section h5 {
        color: #ffffff;
    }

    header .intro-section .buttons {
        margin-top: 15px;
    }
    
    .testimonials, 
    .download { 
        background-attachment: inherit !important; 
    }

    .download .subscription-form .input-box {
        width: 95%;
        height: 50px;
    }

    .standard-button,
    .standard-button2 {
        font-size: 15px;
        min-width: 100px;
        padding: 11px 20px 11px 20px;
    }

    .features .feature:hover .icon-container .icon, 
    footer .contact-button:hover {
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
    }


}

@media (max-width: 320px) {
 
    
    html {
        font-size: 60.5%;
    }
}


@media (min-width: 320px) and (max-width: 640px) and  (orientation: landscape) {
        
    .full-screen h1 {
        font-size: 30px !important;
        line-height: 40px;
        margin-top: 0;
        font-weight: bold;
    }
    .full-screen .intro-section .intro {
       margin-bottom: 0;
            
    }
    
    .full-screen .intro-section h5 {
        
        font-size: 14px;
    }
    .full-screen .intro-section .buttons { 
        margin-top: 0;
    }
    
    .full-screen .standard-button {
        font-size: 14px;
        padding: 8px 5px;
    }
}







/*
$buttonRed :#e21d39;
*/
.clear{
  clear:both;
}
/*helpers*/
.border-radius-5{
  border-radius: 5px;
}
div#owl-demo.owl-carousel.text-center.owl-theme div.owl-wrapper-outer div.owl-wrapper div.owl-item div.item a{
/*   -webkit-box-shadow: 1px 3px 8px 4px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 3px 8px 4px rgba(0,0,0,0.75);
box-shadow: 1px 3px 8px 4px rgba(0,0,0,0.75); */
}
div#owl-demo.owl-carousel.text-center.owl-theme div.owl-wrapper-outer div.owl-wrapper div.owl-item div.item a img{
  background:#ededed;

}
div#owl-demo.owl-carousel.text-center.owl-theme div.owl-wrapper-outer div.owl-wrapper div.owl-item div.item img{
  background:#ededed;
}
